import { Formik, Form } from "formik";
import { makeRequest } from "../requests/makerequest";

import * as yup from "yup";
import {
  Background,
  Container,
  Iflash,
  Formblocks,
  Formcont,
  Fields,
  Button,
  Errors,
  ButtonNavigate,
  FooterCell,
} from "../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import { toastError } from "../toasts/toaststyles";
import { Sidebar } from "../sidebars/sidebar";

import { Footers } from "../footers/footers";

const validationsLogin = yup.object().shape({
  email: yup.string().email("email inválido").required("Email is required."),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
});

export const Loginstyles = ({ data }) => {
  const Shoot = () => data("dart");

  const navigate = useNavigate();

  const handleLogin = async (values, actions) => {
    actions.resetForm();
    await makeRequest
      .post(
        "/auth/login",
        {
          email: values.email,
          password: values.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        navigate("/products");
      })
      .catch((error) => {
        console.log(error.response.data);
        toastError(error.response.data, "Check your email or click here.");
      });
  };

  return (
    <>
      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Formcont>
              <div>
                <h1 style={{ margin: "-5px 0 5px 0", color: "#757575" }}>
                  Login
                </h1>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={handleLogin}
                  validationSchema={validationsLogin}
                >
                  <Form>
                    <Formblocks>
                      <Fields name="email" placeholder="Email" />

                      <Errors
                        component="span"
                        name="email"
                        style={{
                          margin: "-25px 0 0 10px",
                          textStroke: "black",
                        }}
                      />
                    </Formblocks>

                    <Formblocks>
                      <Fields
                        name="password"
                        placeholder="Senha"
                        type="password"
                      />

                      <Errors
                        component="span"
                        name="password"
                        style={{ margin: "-25px 0 0 10px" }}
                      />
                    </Formblocks>

                    <Button className="button" type="submit">
                      Login
                    </Button>
                    <div style={{ marginLeft: "-10px" }}>
                      <ButtonNavigate onClick={Shoot}>
                        Ainda não tem uma conta ? Clique aqui.
                      </ButtonNavigate>
                      <Link
                        to="/forgotpassword"
                        style={{
                          display: "block",
                          marginLeft: "10px",
                          color: "#757575",
                          fontWeight: "bolder",
                        }}
                      >
                        <ButtonNavigate style={{ margin: "0px" }}>
                          Esqueceu sua senha ? Clique aqui.
                        </ButtonNavigate>
                      </Link>
                      <Link
                        to="/verifications"
                        style={{
                          display: "block",
                          marginLeft: "10px",
                          color: "#757575",
                          fontWeight: "bolder",
                        }}
                      >
                        <ButtonNavigate style={{ margin: "0px" }}>
                          Ainda não verificou seu e-mail?
                        </ButtonNavigate>
                      </Link>
                    </div>
                  </Form>
                </Formik>
              </div>
            </Formcont>
          </div>
        </Container>
        <Footers />
        <FooterCell>
          <div style={{ color: "#c4cbda" }}>
            Automate everything with Djouleco
          </div>
        </FooterCell>
      </Background>
    </>
  );
};
