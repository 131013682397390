import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeRequest } from "../requests/makerequest";
import { useQueryClient } from "@tanstack/react-query";
import {
  AuthArrow,
  AuthLink,
  DjoulecoLogo,
  SidebarLinks,
  SidebarNavs,
  SidebarUl,
  TryLink,
} from "../styles/styles";

export const Sidebar = () => {
  // const [click, setClick] = useState(false);

  // const handleClick = () => setClick(!click);
  // const closeMobileMenu = () => setClick(false);

  const [state, setState] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest.get("/users/viewUserPage");
        setState(true);
      } catch (error) {
        // navigate("/register");
        setState(false);
      }
    };

    fetchData();
  }, [navigate]);

  const queryClient = useQueryClient();
  const logout = async () => {
    setState(false);

    await makeRequest
      .post("/auth/logout", {}, { withCredentials: true })
      .then((response) => {
        console.log(response);
        navigate("/register");
        queryClient.removeQueries();
        localStorage.setItem("logoutaccount", null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <SidebarNavs>
        <SidebarUl>
          <div>
            <Link
              to="/"
              //  onClick={closeMobileMenu}
            >
              <DjoulecoLogo
                src="images/new-logo-trasnparente.png"
                alt="Djouleco Logo"
              />
            </Link>
          </div>
          <SidebarLinks>
            {/* <li>
              <Link to="/register" onClick={closeMobileMenu}>
                Login
              </Link>
            </li> */}
            {/* {state ? (
              ""
            ) : (
              <>
                <li>
                  <Link to="/register" onClick={closeMobileMenu}>
                    Register
                  </Link>
                </li>
              </>
            )} */}
            {state ? (
              <>
                <li>
                  <AuthLink
                    to="/products"
                    // onClick={closeMobileMenu}
                  >
                    Products
                  </AuthLink>
                </li>
              </>
            ) : (
              <></>
            )}
            {/* {state ? (
              <>
                <li>
                  <Link to="/paymentsadds" onClick={closeMobileMenu}>
                    Payment Add
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )} */}

            {/* <li>
              <Link to="/wages" onClick={closeMobileMenu}>
                Wages
              </Link>
            </li> */}
            {state ? (
              <>
                <li>
                  <AuthLink
                    to="/workspace"
                    // onClick={closeMobileMenu}
                  >
                    Workspace
                  </AuthLink>
                </li>
              </>
            ) : (
              <></>
            )}
            {state ? (
              <>
                <li>
                  <TryLink
                    to="/register"
                    onClick={() => {
                      // closeMobileMenu();
                      logout();
                    }}
                  >
                    Logout <AuthArrow />
                  </TryLink>
                </li>
              </>
            ) : (
              <>
                <li>
                  <AuthLink to="/register">Login</AuthLink>
                </li>
              </>
            )}
            {state ? (
              <></>
            ) : (
              <>
                <li>
                  <TryLink
                    to="/workspace"
                    // onClick={closeMobileMenu}
                  >
                    Tente Djouleco <AuthArrow />
                  </TryLink>
                </li>
              </>
            )}
          </SidebarLinks>
        </SidebarUl>
      </SidebarNavs>
    </>
  );
};
