export const text19 = `
After you have authorized the card you can finally click play or stop, the web interface is simpler and contains a field that runs your automation when you click on it, however note that next to this button there is another button to save, if you If you have made changes to your automation it will be saved in our database securely, however, this does not mean that it will update itself in the cloud, if it is running and you change it, we strongly recommend that you close your automation, update it, and put -a to run again, the only difference between the web and the app are the interfaces, while on the web you press the know button in the app you need to enter an email.
`;

export const img19 = `/images/puttingcloud2.png`;
export const Component19 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-142px 0 0 135px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here to start <br />
          your web automation
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-12px 0 0 115px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here to update <br />
          your web automation
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "135px 0 0 115px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here to start <br />
          your web automation
        </span>
      </div>
    </div>
  );
};
