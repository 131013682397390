export const text14 = `
After finishing an automation, they will be arranged in an area where each block will be linked to a node, despite the nodes being iterative, the flow of each automation will be part of the subsequent node, that is, one click after the other in an infinite looping, where the properties of each click are defined by you, this module may have confused you but you see, there is no other way for the clicks to go, they click where they should click, in infinite sequence (that's literally it).
`;

export const img14 = `/images/mechanics7.png`;
export const Component14 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-155px 0 0 150px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          The automations once <br />
          ready will appear <br />
          here, arranged <br />
          like this
        </span>
      </div>
    </div>
  );
};
