import Popup from "reactjs-popup";
import { makeRequest } from "../requests/makerequest";
import {
  Btnpopup,
  Headerpopup,
  PopupStyle,
  Popupbtn,
  SaveAll,
  Startpopup,
  Textpopup,
} from "../styles/styles";

export const SaveAlls = ({ pid, act }) => {
  const handleSaveAll = async (postId) => {
    try {
      const res = await makeRequest.get("/users/viewUserPage");
      try {
        await makeRequest.post("/deploys/auto-deploy", {
          value: res.data.result[0].email,
          postId: postId,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup
        trigger={
          <Popupbtn disabled={act === 1 ? false : true}>
            <SaveAll size={23} act={act} />
          </Popupbtn>
        }
        modal
        nested
        contentStyle={PopupStyle}
      >
        {(close) => (
          <Startpopup>
            <Headerpopup>
              Do you want to save/update this automation?
            </Headerpopup>
            <Textpopup>
              After saving an automation, wait a period of 3 to 5 minutes,
              Djoule will create a space completely yours in this time, then you
              can start or interrupt it.
            </Textpopup>
            <Btnpopup
              onClick={() => {
                handleSaveAll(pid);
                close();
              }}
            >
              SAVE ALL
            </Btnpopup>
          </Startpopup>
        )}
      </Popup>
    </>
  );
};
