import React, { useEffect, useState } from "react";
import { Txtlime } from "../styles/styles";

export const TxtShuffleComponent = ({ txt }) => {
  const [textArray, setTextArray] = useState([]);
  const [textIndex, setTextIndex] = useState(0);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    if (!txt) {
      return; // Exit the effect if txt is not defined yet
    }

    setTextArray(txt.split(""));
  }, [txt]);

  useEffect(() => {
    if (animationCompleted) {
      return; // Exit the effect if animation is completed
    }

    const intervalID = setInterval(() => {
      if (textIndex >= textArray.length) {
        clearInterval(intervalID);
        setAnimationCompleted(true); // Mark animation as completed
        return;
      }

      setTextIndex((prevIndex) => prevIndex + 1);
    }, 10);

    return () => clearInterval(intervalID);
  }, [textIndex, textArray.length, animationCompleted]);

  return <Txtlime>{textArray.slice(0, textIndex).join("")}</Txtlime>;
};
