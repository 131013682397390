export const text17 = `
Did you notice that when starting an automation test video, the steps (clicks) you took will be reproduced on a screen arranged in the region, you can end it at any time by clicking on the x icon, if you are wondering if it will reproduce infinitely, unfortunately no, we have enabled a security lock so that the resource does not consume too much of your computer, this works in a way that every two loopings complete the cycle ends and the automation video stops instantly, two loopings already give enough evidence that the automation is in perfect working order and ready to go up.
`;

export const img17 = `/images/testingautos2.png`;
export const Component17 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-117px 0 0 -165px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          If you want to end the automation video
        </span>
      </div>
    </div>
  );
};
