export const text3 = `
Downloading the tool is very simple, just click on the icon you want and the installation begins, wait a few minutes, when starting you will see a login screen similar to the one you saw here, just enter the SAME login that you entered when downloading, if you have difficulties in this part we recommend that you watch forums and news networks on how to access Djouleco, remember to read everything carefully, within the djoule website there are ways to recover passwords and resend email confirmations, be aware of fraud.
`;
export const img3 = `/images/register2.png`;

export const Component3 = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};
