import {
  Background,
  Container,
  Windows,
  Iflash,
  Apple,
  Linux,
  HomeH2,
  HomeH1,
  Bloguehome,
  ContentHome,
  AboutContainerProd,
  AuthArrow,
  AboutContent,
} from "../components/styles/styles";
import { makeRequest } from "../components/requests/makerequest";
import { toastError } from "../components/toasts/toaststyles";
import { Sidebar } from "../components/sidebars/sidebar";

export const Products = () => {
  const handleDownload = async () => {
    try {
      const res = await makeRequest("/users/getDownload");
      const link = document.createElement("a");
      link.href = process.env.REACT_APP_GIT_WIN_URL;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log(res);
    } catch (err) {
      toastError("You must be logged in to download. If you want, click here.");
      console.log(err.response.data);
    }
  };

  return (
    <>
      {/* <StyledBlobComponent
        style={{ position: "fixed", bottom: "85%", right: "65%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "50%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "10%", left: "20%" }}
      /> */}
      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <ContentHome>
            <Bloguehome>About</Bloguehome>
            <HomeH1>
              Windows <br />
              Installer
            </HomeH1>
            <HomeH2>
              Você pode baixar o instalador do Djouleco em sua máquina e começar
              a usá-la, ou pode procurar uma automação existente para manuseá-la
              e definir seu status na nuvem. Clique em saiba mais para entender
              melhor.
            </HomeH2>
            <AboutContainerProd>
              <AboutContent>
                Saiba mais <AuthArrow />
              </AboutContent>
              <AboutContent onClick={handleDownload}>
                Download to Windowns
              </AboutContent>
            </AboutContainerProd>
          </ContentHome>
          <div>
            <Windows onClick={handleDownload} />
            {/* <Chartwohome src="../images/white-back.jpg" alt="chartwo" /> */}
          </div>
        </Container>
        <Container>
          <ContentHome>
            <Bloguehome>About</Bloguehome>
            <HomeH1>
              MacIos <br />
              Comming Soon
            </HomeH1>
            <HomeH2>
              Infelizmente não temos data prevista para o lançamento do Djouleco
              para MacIos, recomendamos fortissimamente que utilize nossa
              interface via Web para entender melhor até a chegada do
              aplicativo, com sua ajuda, faremos isso o mais rápido possível
            </HomeH2>
            <AboutContainerProd>
              <AboutContent>
                <AuthArrow />
                Saiba mais
              </AboutContent>
              <AboutContent onClick={handleDownload}>
                MacIos comming soon
              </AboutContent>
            </AboutContainerProd>
          </ContentHome>
          <div>
            <Apple />
            {/* <Chartwohome src="../images/white-back.jpg" alt="chartwo" /> */}
          </div>
        </Container>
        <Container>
          <ContentHome>
            <Bloguehome>About</Bloguehome>
            <HomeH1>
              Linux <br />
              Comming Soon
            </HomeH1>
            <HomeH2>
              Infelizmente não temos data prevista para o lançamento do Djouleco
              para Linux, recomendamos fortissimamente que utilize nossa
              interface via Web para entender melhor até a chegada do
              aplicativo, com sua ajuda, faremos isso o mais rápido possível.
            </HomeH2>
            <AboutContainerProd>
              <AboutContent>
                Tente Djouleco <AuthArrow />
              </AboutContent>
              <AboutContent onClick={handleDownload}>
                Linux comming soon
              </AboutContent>
            </AboutContainerProd>
          </ContentHome>
          <div>
            <Linux />
            {/* <Chartwohome src="../images/white-back.jpg" alt="chartwo" /> */}
          </div>
        </Container>
      </Background>
    </>
  );
};
