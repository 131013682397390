import {
  Background,
  Container,
  Iflash,
  // MoneyLink,
  FooterCell,
  ContentHome,
  HomeH1,
  Bloguehome,
  HomeH2,
  AboutContainerhome,
  AboutContent,
  AuthArrow,
  Chartwohome,
  MoneyHome,
  // HomeLine,
} from "../components/styles/styles";
import { Sidebar } from "../components/sidebars/sidebar";
// import { TextWages } from "../components/wages/textWage";
import { Footers } from "../components/footers/footers";

export const Home = () => {
  return (
    <>
      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <ContentHome>
            <Bloguehome>Blogue</Bloguehome>
            <HomeH1>
              Introdução <br />
              DjouleCo
            </HomeH1>
            <HomeH2>
              Você lembra o quão chato era correr atrás de um programa ou
              serviço que automatizesse aquela tarefa ruim do seu trabalho,
              fique tranquilo, Djouleco resolveu isso pra você, teste ainda hoje
              e descubra o quão potente nossa ferramenta pode ser.
            </HomeH2>
            <AboutContainerhome>
              <AboutContent to="/workspace">
                Tente Djouleco <AuthArrow />
              </AboutContent>
              <AboutContent to="/docs">Leia sobre Djouleco</AboutContent>
            </AboutContainerhome>
          </ContentHome>
          <div style={{ display: "flex" }}>
            <Chartwohome src="../images/white-back.jpg" alt="chartwo" />
            <Chartwohome src="../images/white-back.jpg" alt="chartwo" />
          </div>
        </Container>
        <MoneyHome>{/* <HomeLine></HomeLine> */}</MoneyHome>
        <Footers />
        <FooterCell>
          <div>Automatize tudo com DjouleCo</div>
        </FooterCell>
      </Background>
    </>
  );
};
