// import { useEffect, useState } from "react";
import { makeRequest } from "../requests/makerequest";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSpring, animated, config } from "react-spring";

export const TextWages = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery(
    ["getWages"],
    () =>
      makeRequest.get("/managers/get-wages").then((res) => {
        return res.data;
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["getWages"]);
        }, 10000);
      },
    }
  );

  const numero = parseFloat(data || 0);

  // Configuração da animação
  const props = useSpring({
    value: numero,
    from: { value: numero },
    delay: 1000,
    config: config.default,
  });

  const formatNumber = (value) => {
    return `$${value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  return (
    <span>
      {error ? (
        "Error!"
      ) : isLoading ? (
        "Is Loading..."
      ) : (
        <animated.span>{props.value.to(formatNumber)}</animated.span>
      )}
    </span>
  );
};
