// import { StyledBlobComponent } from "../styles/styles";
import { Chipco } from "./chipco";
import {
  Container,
  Bbox,
  Lil,
  Rigth,
  Date,
  Flex,
  Submit,
  Dates,
  ErrorMsg,
  Spanr,
  Hashcont,
  Citem,
  Citemm,
  WhiteLine,
  CardContainer,
  Card,
  Front,
  Back,
  CVV,
  CardCvv,
  Spaceflex,
  FormGroup,
  FormRow,
  Contpay,
  NoStripeInputW,
  FormOtherGroup,
  DatesS,
  Lils,
  Lilss,
} from "./paymentaddsstyles";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  PromisseError,
  PromisseLoading,
  PromisseSucess,
} from "../toasts/toaststyles";
import { useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../requests/makerequest";
import { SiMastercard } from "react-icons/si";
import { toast } from "react-toastify";
import { useState } from "react";
import { Footers } from "../footers/footers";

export const PaymentAdds = () => {
  const [stateRotate, setStateRotate] = useState(false);
  const handleCvvFocus = () => setStateRotate(!stateRotate);

  const [disable, setDisable] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    setDisable(true);
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(
        CardCvcElement,
        CardExpiryElement,
        CardNumberElement
      ),
    });
    if (error || cardNickname === "") {
      setErrorNickname("The nickname required");
      console.log("[error]", error);
      setCvcElementState(error.code);
      setDisable(false);
      if (error.code === "incomplete_number") {
        setCardNumberState("invalid_number");
        setDisable(false);
      }
      if (
        error.code === "invalid_expiry_year_past" ||
        error.code === "incomplete_expiry" ||
        error.code === "invalid_expiry_year"
      ) {
        setExpiryElementState("invalid_expiry_year_past");
        setDisable(false);
      }
    } else {
      const monitoredPromise = makeRequest
        .post("/payments/add-payment-method", {
          paymentMethodId: paymentMethod.id,
          cardNickname: cardNickname,
        })
        .then(function (response) {
          console.log("Subscription created!", response.data);
          // successPayMethod(`${response.data}`);
          elements.getElement(CardNumberElement).clear();
          elements.getElement(CardCvcElement).clear();
          elements.getElement(CardExpiryElement).clear();
          setCardNickname("");
          setDisable(false);
          queryClient.invalidateQueries(["cardLength"]);
          return Promise.resolve(response.data);
        })
        .catch(function (error) {
          console.log(error.response.data.error);
          console.log(error.response.data);
          // errorPayMethod(`${error.response.data}`);
          setDisable(false);
          return Promise.reject(error.response.data);
        });

      const success = PromisseSucess("Payment method add!").success;

      const pending = PromisseLoading("Adding payment method add.").pending;

      const error = PromisseError("Something was wrong to credentials").error;

      const toastOptions = {
        success,
        pending,
        error,
      };

      toast.promise(monitoredPromise, toastOptions);
    }
  };

  const options = {
    placeholder: "",
    style: {
      base: {
        color: "darkgray",
        fontSize: "24px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "darkgray" },
        "::placeholder": { color: "darkgray" },
      },

      invalid: {
        color: "darkgray",
      },
    },
  };

  const optionse = {
    style: {
      base: {
        color: "darkgray",
        fontSize: "24px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "darkgray" },
        "::placeholder": { color: "darkgray" },
      },
      invalid: {
        color: "darkgray",
      },
    },
  };

  const [cardNumberState, setCardNumberState] = useState("");
  //prettier-ignore
  const cardNumberChange = (e) => setCardNumberState(e?.error?.code ?? undefined);

  const [expiryElementState, setExpiryElementState] = useState("");
  //prettier-ignore
  const handleExpiryElement = (e) => setExpiryElementState(e?.error?.code ?? undefined)

  const [cvcElementState, setCvcElementState] = useState("");
  //prettier-ignore
  const handleCvcElement= (e) => setCvcElementState(e?.error?.code ?? undefined)

  const [cardNickname, setCardNickname] = useState("");
  //prettier-ignore
  const handleNickName = (e) => {setCardNickname(e.target.value); setErrorNickname("")}

  const [errorNickname, setErrorNickname] = useState("");

  return (
    <>
      {/* <StyledBlobComponent
        style={{ position: "fixed", bottom: "75%", right: "75%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "50%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Contpay>
          <Container>
            <CardContainer>
              <Card stateRotate={stateRotate}>
                <Front>
                  <Spaceflex>
                    <Chipco />
                    <SiMastercard
                      style={{
                        filter: "drop-shadow(0 2px 4px rgba(43, 44, 44, 0.5))",
                        fontSize: "48px",
                        color: "white",
                      }}
                    />
                  </Spaceflex>
                  <Hashcont>
                    <Spanr># # # #</Spanr>
                    <Spanr># # # #</Spanr>
                    <Spanr># # # #</Spanr>
                    <Spanr># # # #</Spanr>
                  </Hashcont>
                  <Spaceflex>
                    <div>
                      <label>
                        <Citem>Card Holder</Citem>
                        <Citemm>FULL NAME</Citemm>
                      </label>
                    </div>
                    <div>
                      <label>
                        <Citem>Expires</Citem>
                        <Citemm>MM / YY</Citemm>
                      </label>
                    </div>
                  </Spaceflex>
                </Front>
                <Back>
                  <CardCvv></CardCvv>
                  <CVV>CVV</CVV>
                  <WhiteLine></WhiteLine>
                </Back>
              </Card>
            </CardContainer>

            <Bbox>
              <Rigth>
                <form onSubmit={handleSubmit}>
                  <NoStripeInputW>
                    <label>
                      <Lil>Card number</Lil>
                    </label>
                    <FormGroup style={{ marginTop: "0px" }}>
                      <FormRow>
                        <CardNumberElement
                          options={options}
                          onChange={cardNumberChange}
                        />
                      </FormRow>
                    </FormGroup>
                    <ErrorMsg style={{ marginRight: "-10px" }}>
                      {cardNumberState === "invalid_number"
                        ? "Invalid card number"
                        : ""}
                    </ErrorMsg>
                  </NoStripeInputW>
                  <NoStripeInputW>
                    <label>
                      <Lil>Card nickname</Lil>
                    </label>
                    <FormGroup style={{ marginTop: "0px" }}>
                      <FormRow style={{ marginTop: "10px" }}>
                        <input
                          onChange={handleNickName}
                          style={{
                            width: "100%",
                            fontSize: "24px",
                            fontSmoothing: "antialiased",
                            background: "transparent",
                            color: "darkgray",
                            outline: "none",
                            border: "none",
                            fontFamily: "sans-serif",
                          }}
                          value={cardNickname}
                        />
                      </FormRow>
                    </FormGroup>
                    <ErrorMsg style={{ marginRight: "-10px" }}>
                      {cardNickname === "" ? errorNickname : ""}
                    </ErrorMsg>
                  </NoStripeInputW>
                  <Date>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Lils>Expiration date</Lils>
                      <Lilss>CVV</Lilss>
                    </label>
                    <Flex>
                      <DatesS>
                        <FormOtherGroup>
                          <FormRow>
                            <CardExpiryElement
                              options={optionse}
                              onChange={handleExpiryElement}
                            />
                          </FormRow>
                        </FormOtherGroup>
                        <ErrorMsg>
                          {expiryElementState === "invalid_expiry_year_past"
                            ? "Invalid date"
                            : ""}
                        </ErrorMsg>
                      </DatesS>
                      <Dates style={{ marginTop: "10px", width: "223px" }}>
                        <FormOtherGroup>
                          <FormRow>
                            <CardCvcElement
                              options={optionse}
                              onChange={handleCvcElement}
                              onFocus={handleCvvFocus}
                              onBlur={handleCvvFocus}
                            />
                          </FormRow>
                        </FormOtherGroup>
                        <ErrorMsg>
                          {cvcElementState === "incomplete_cvc"
                            ? "Invalid cvc"
                            : ""}
                        </ErrorMsg>
                      </Dates>
                    </Flex>
                    <Submit disabled={disable}>Add pay method</Submit>
                  </Date>
                </form>
              </Rigth>
            </Bbox>
          </Container>
        </Contpay>
      </div>
      <Footers />
    </>
  );
};
