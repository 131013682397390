import {
  Background,
  CentralizeSp,
  Eye,
  IconContent,
  Iflash,
  Leftbox,
  Rightbox,
  Titles,
  Txtlimes,
  WorkspaceDiv,
  iconsStyleWageAuto,
  iconsStyleWageAuto2,
} from "../styles/styles";
import { Sidebar } from "../sidebars/sidebar";
import { Footers } from "../footers/footers";
import { Inputautos } from "../inputautos/inputautos";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../requests/makerequest";
import { TiStarburst } from "react-icons/ti";
import { BiSolidPyramid } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PausesPlays } from "../pauses-plays/pauses-plays";
import { Trashs } from "../Trashs/trashs";
import { ConfirmAddPays } from "../confirmAddPays/confirmAddPays";
import { SaveAlls } from "../saveAlls/saveAlls";

export const Workspaces = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest.get("/users/viewUserPage");
      } catch (error) {
        alert("Você precisa logar para prosseguir");
        navigate("/register");
      }
    };

    fetchData();
  }, [navigate]);

  const { isLoading, error, data } = useQuery(["click"], () =>
    makeRequest.get("/clicks/get-all-clicks").then((res) => {
      return res.data;
    })
  );

  const handleClickEye = (arg) => {
    navigate(`/workspace/${arg}`);
  };

  return (
    <>
      <Background>
        <Sidebar />
        <Iflash />
        <CentralizeSp>
          <WorkspaceDiv>
            <Leftbox>
              <Inputautos />
              <div style={{ marginTop: "70px" }}>
                {error
                  ? "Error..."
                  : isLoading
                  ? "Is loading..."
                  : data.map((item, index) => {
                      return (
                        <div key={index}>
                          <Titles>
                            {item.title}
                            <IconContent>
                              <Eye
                                onClick={() => handleClickEye(item.postId)}
                                size={18}
                              />

                              <SaveAlls pid={item.postId} act={item.active} />

                              {item.active === 1 ? (
                                <PausesPlays
                                  pid={item.postId}
                                  py={item.python}
                                />
                              ) : (
                                <ConfirmAddPays pid={item.postId} />
                              )}

                              <Trashs pid={item.postId} />
                            </IconContent>
                          </Titles>
                        </div>
                      );
                    })}
              </div>
            </Leftbox>
            <Rightbox>
              <TiStarburst
                color=" #fefefe"
                size={42}
                style={iconsStyleWageAuto}
              />
              <BiSolidPyramid style={iconsStyleWageAuto2} />

              <Txtlimes>
                <div style={{ overflowX: "auto", height: "65vh" }}>
                  O que é um Hashcode e como copiar automações Um hashcode é
                  como uma senha especial que você precisa para copiar uma
                  automação feita por outra pessoa para sua própria conta. É
                  como ter a chave para abrir uma porta mágica que traz essa
                  automação até você. Para obter um hashcode, você não precisa
                  ser um especialista, basta ir a locais específicos na
                  internet, como fóruns ou sites onde as pessoas compartilham
                  essas automações. Aí, você gentilmente pergunta a alguém que
                  já possui a automação se pode lhe fornecer o hashcode.
                  Hashcode é a maneira de possibilitar o compartilhamento de
                  coisas legais sem a necessidade de ser um especialista em
                  programação. É como pedir uma senha para desbloquear algo
                  incrível que outra pessoa criou. Com esta senha você poderá
                  ter a mesma automação em sua conta e aproveitar todas as suas
                  vantagens. É fácil e útil!
                </div>
              </Txtlimes>
            </Rightbox>
          </WorkspaceDiv>
        </CentralizeSp>
        <Footers />
      </Background>
    </>
  );
};
