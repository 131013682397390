export const text16 = `
In this section you can start automation tests, we at djoule highly recommend that you use this option because this is the only way you will be guaranteed that your automations are really working, each automation is represented by a name, so you can start and end a one if you prefer.
`;

export const img16 = `/images/testingautos1.png`;
export const Component16 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-212px 0 0 -415px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here to come to this region
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-145px 0 0 -265px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here to start the automation test
        </span>
      </div>
    </div>
  );
};
