export const text15 = `
The change popups store information about your automations, more specifically about the clicks of your automations, you can easily change them in a conscientious way by clicking on the 3 dots, some popups will appear yellow which indicates that a click copies the text and others appear green which indicates that the previously copied text has been pasted, some popus will appear with changes that you put in such as writing, navigating, and intervals, all these 3 sites can be customized as you wish.
`;

export const img15 = `/images/mechanics8.png`;
export const Component15 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-197px 0 0 -335px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          The 3 dots opened <br />
          the change popup.
        </span>
        ---{">"}
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-107px 0 0 -275px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          Copy popups will <br />
          appear like this
        </span>
        ---{">"}
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-160px 0 0 -15px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          Paste popups will
          <br /> appear like this
        </span>
        <span
          style={{
            position: "absolute",
            transform: "rotate(30deg)",
            width: "100px",
            margin: "30px 0 0 0",
          }}
        >
          {" "}
          ---{">"}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-7px 0 0 -405px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          Changeable popups will
          <br /> appear like this
        </span>
        ---{">"}
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "43px 0 0 65px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Write</span>
        <span
          style={{
            position: "absolute",
            transform: "rotate(30deg)",
            width: "100px",
            margin: "30px 0 0 0",
          }}
        >
          {" "}
          ---{">"}
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "84px 0 0 30px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Navigate</span>
        ---{">"}
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "120px 0 0 30px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Interval</span>
        <span
          style={{
            position: "absolute",
            transform: "rotate(-30deg)",
            width: "100px",
            margin: "-25px 0 0 0",
          }}
        >
          {" "}
          ---{">"}
        </span>
      </div>
    </div>
  );
};
