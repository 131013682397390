import {
  Background,
  Codebox,
  Codeboxtop,
  Coloredballs,
  ContainerDocs,
  ContentDocs,
  Iflash,
  Lisearch,
  RankD,
  // StyledBlobComponent,
  Txtlime,
  Txtlimes,
} from "../styles/styles";
import { Sidebar } from "../sidebars/sidebar";
import { FootersDocs, FootersDocsR } from "../footers/footers";
import { docsdata } from "./docsdata";
import React, { useRef } from "react";

export const Docs = () => {
  const scrollToRef = useRef([]);

  const handleClick = (num) => {
    if (scrollToRef.current[num]) {
      window.scrollTo({
        top: scrollToRef.current[num].offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      {/* <StyledBlobComponent
        style={{ position: "fixed", bottom: "75%", right: "75%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "50%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}

      <Background>
        <Sidebar />
        <ContainerDocs>
          {docsdata.map((item, index) => {
            return (
              <Codebox
                key={index}
                index={index}
                docsdatalen={docsdata.length}
                ref={(el) => (scrollToRef.current[index] = el)}
              >
                <Codeboxtop>
                  <Coloredballs />
                  <Coloredballs />
                  <Coloredballs />
                </Codeboxtop>
                <Txtlime style={{ padding: "15px", textAlign: "justify" }}>
                  {item.text}
                </Txtlime>
                <ContentDocs img={item.image} index={index}>
                  {item.component}
                </ContentDocs>
              </Codebox>
            );
          })}
        </ContainerDocs>
        <RankD
          style={{
            position: "fixed",
            bottom: "100px",
            borderRadius: "15px",
            height: "calc(100% - 280px)",
            padding: "20px",
            border: "1px solid #fefefe",
            boxShadow: "0px 0px 5px 0px #1a1a1a",
          }}
        >
          <div
            style={{ overflow: "auto", height: "100%", overflowX: "hidden" }}
          >
            {docsdata.map((item, index) => {
              return (
                <>
                  <Txtlimes
                    key={index}
                    onClick={() => handleClick(index)}
                    style={{
                      textAlign: "justify",
                      marginLeft: "auto",
                      marginRight: "0",
                    }}
                  >
                    <Lisearch>
                      {index + 1}.{item.title}
                    </Lisearch>
                  </Txtlimes>
                </>
              );
            })}
          </div>
        </RankD>
        <RankD
          style={{
            position: "fixed",
            right: "0",
            bottom: "100px",
            borderRadius: "15px",
            height: "calc(100% - 280px)",
            padding: "20px",

            border: "1px solid #fefefe",
            boxShadow: "0px 0px 5px 0px #1a1a1a",
          }}
        ></RankD>
        <FootersDocs />
        <FootersDocsR />

        <Iflash />
      </Background>
    </div>
  );
};
