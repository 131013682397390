export const text8 = `
The image below shows what happens when you start counting to form an automation, this is just one of the many mechanics you have to interact with Djoule, from here we will teach you how you can click on the ideal places, deviate from prohibited sites among other fantastic things, remember that from here your creativity is the limit so pay attention to good automations and if you have difficulty contact us on forums and social networks, good job.
`;
export const img8 = `/images/mechanics1.png`;
export const Component8 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-200px 0 0 -410px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          When you click on the input a Google chrome will appear, identical{" "}
          <br /> to this screen
        </span>
      </div>
    </div>
  );
};
