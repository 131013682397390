export const text7 = `
The iteration popup for initiating an automation is super interactive but even so we will explain better how it works, when interacting with it you will notice that a chrome will be opened, this chrome by default cannot be changed, there are critical reasons for using it, note that it comes virgin as if they had never been modified with logins among other things, that's the advantage it has, we'll talk more about it in the next chapter.
`;
export const img7 = `/images/djouleAppearance4.png`;
export const Component7 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-110px 0 0 -415px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}-
        <span style={{ background: "#69362cc9" }}>
          For the automation <br />
          initiation popup to <br />
          appear, just have an <br />
          automation selected <br />
          and click on this <br />
          icon
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-190px 0 0 -290px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Init Count</span>
        <div
          style={{
            transform: "rotate(45deg)",
            position: "absolute",
            margin: "30px 0 0 70px",
            width: "100%",
          }}
        >
          ---{">"}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-220px 0 0 -200px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Pause Count</span>
        <div
          style={{
            transform: "rotate(60deg)",
            position: "absolute",
            margin: "45px 0 0 60px",
            width: "100%",
          }}
        >
          -----{">"}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-220px 0 0 -70px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Resume Count</span>
        <div
          style={{
            transform: "rotate(90deg)",
            position: "absolute",
            margin: "60px 0 0 0px",
            width: "100%",
          }}
        >
          ----{">"}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-220px 0 0 72px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Resume Count</span>
        <div
          style={{
            transform: "rotate(120deg)",
            position: "absolute",
            margin: "52px 0 0 -70px",
            width: "100%",
          }}
        >
          -----{">"}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-190px 0 0 214px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Save automation</span>
        <div
          style={{
            transform: "rotate(140deg)",
            position: "absolute",
            margin: "25px 0 0 -155px",
            width: "100%",
          }}
        >
          ------{">"}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-158px 0 0 248px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>Close all</span>
        <div
          style={{
            transform: "rotate(160deg)",
            position: "absolute",
            margin: "-7px 0 0 -103px",
            width: "100%",
          }}
        >
          ---{">"}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-125px 0 0 200px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          {"<"}---Retros is a <br />
          count
        </span>
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-55px 0 0 205px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          Defines whether or <br />
          not to capture <br /> images
        </span>
        <div
          style={{
            transform: "rotate(230deg)",
            position: "absolute",
            margin: "-165px 0 0 -173px",
            width: "100%",
          }}
        >
          --{">"}
        </div>
      </div>
    </div>
  );
};
