export const text11 = `
In the same way that a text is copied, another is pasted, regardless of whether there are clicks between them or not, that is, you can copy a text by clicking twice quickly (yellow message will appear) then you can click normally and in the future, if you want, you can click again twice quickly and the text will be pasted (green message will appear), we have developed this exclusive mechanism for you who want to use djoule, think about the possibilities that this can bring you and the best thing about it is that all these services so far are totally free and very easy to use.
`;

export const img11 = `/images/mechanics4.png`;
export const Component11 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-80px 0 0 -380px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          This was the text we copied
        </span>
        -{">"}
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-53px 0 0 -380px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          This was the text pasted, after <br />
          the second double click
        </span>
        -{">"}
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "65px 0 0 -25px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          What indicates to you that <br />
          the text was pasted is this
        </span>
        ---{">"}
      </div>
    </div>
  );
};
