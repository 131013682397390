import { text1, img1, Component1 } from "./docbox1";
import { Component10, img10, text10 } from "./docbox10";
import { Component11, img11, text11 } from "./docbox11";
import { Component12, img12, text12 } from "./docbox12";
import { Component13, img13, text13 } from "./docbox13";
import { Component14, img14, text14 } from "./docbox14";
import { Component15, img15, text15 } from "./docbox15";
import { Component16, img16, text16 } from "./docbox16";
import { Component17, img17, text17 } from "./docbox17";
import { Component18, img18, text18 } from "./docbox18";
import { Component19, img19, text19 } from "./docbox19";
import { Component2, img2, text2 } from "./docbox2";
import { Component3, img3, text3 } from "./docbox3";
import { Component4, img4, text4 } from "./docbox4";
import { Component5, img5, text5 } from "./docbox5";
import { Component6, img6, text6 } from "./docbox6";
import { Component7, img7, text7 } from "./docbox7";
import { Component8, img8, text8 } from "./docbox8";
import { Component9, img9, text9 } from "./docbox9";

export const docsdata = [
  {
    title: "Welcome to DJoule, knowing tool.",
    text: text1,
    image: img1,
    component: <Component1 />,
  },
  {
    title: "Register your account in djoule.",
    text: text2,
    image: img2,
    component: <Component2 />,
  },
  {
    title: "Starting tool access.",
    text: text3,
    image: img3,
    component: <Component3 />,
  },
  {
    title: "Djoule tool appearance #1.",
    text: text4,
    image: img4,
    component: <Component4 />,
  },
  {
    title: "Djoule tool appearance #2.",
    text: text5,
    image: img5,
    component: <Component5 />,
  },
  {
    title: "Djoule tool appearance #3.",
    text: text6,
    image: img6,
    component: <Component6 />,
  },
  {
    title: "Djoule tool appearance #4.",
    text: text7,
    image: img7,
    component: <Component7 />,
  },
  {
    title: "Djoule mechanics #1.",
    text: text8,
    image: img8,
    component: <Component8 />,
  },
  {
    title: "Djoule mechanics #2.",
    text: text9,
    image: img9,
    component: <Component9 />,
  },
  {
    title: "Djoule mechanics #3.",
    text: text10,
    image: img10,
    component: <Component10 />,
  },
  {
    title: "Djoule mechanics #4.",
    text: text11,
    image: img11,
    component: <Component11 />,
  },
  {
    title: "Djoule mechanics #5.",
    text: text12,
    image: img12,
    component: <Component12 />,
  },
  {
    title: "Djoule mechanics #6.",
    text: text13,
    image: img13,
    component: <Component13 />,
  },
  {
    title: "Djoule mechanics #7.",
    text: text14,
    image: img14,
    component: <Component14 />,
  },
  {
    title: "Djoule mechanics #8.",
    text: text15,
    image: img15,
    component: <Component15 />,
  },
  {
    title: "Testing automations #1.",
    text: text16,
    image: img16,
    component: <Component16 />,
  },
  {
    title: "Testing automations #2.",
    text: text17,
    image: img17,
    component: <Component17 />,
  },
  {
    title: "Putting in the cloud #1.",
    text: text18,
    image: img18,
    component: <Component18 />,
  },
  {
    title: "Putting in the cloud #2.",
    text: text19,
    image: img19,
    component: <Component19 />,
  },
];
