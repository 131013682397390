// import axios from "axios";
// export const makeRequest = axios.create({
//   baseURL: "http://localhost:8800/api",
//   withCredentials: true,
// });

// export const makeRequest = axios.create({
//   baseURL: "https://djouleco-server.onrender.com/api",
//   withCredentials: true,
// });

import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8800/api"
    : "https://djouleco-server-0dqz.onrender.com/api";

export const makeRequest = axios.create({
  baseURL,
  withCredentials: true,
});
