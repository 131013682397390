export const text10 = `
One of the most fantastic techniques that djoule can offer you is the mechanism to copy and paste texts with double click, venture through the pages and place information in specific places by copying and pasting different texts, in this topic you will see how you can copy a text of this form and in the next topic you can see how you can paste the text that was copied. It is worth mentioning that a double click generates a normal click and a copy text click, so the first click of the double click will act as a normal single click and the second will act as a copy text.
`;
export const img10 = `/images/mechanics3.png`;
export const Component10 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-125px 0 0 -395px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          When you double click it <br />
          copies the general text from
          <br /> where the double click was
        </span>
        ---{">"}
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "65px 0 0 -25px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          What indicates to you that <br />
          the text was copied is this
        </span>
        ---{">"}
      </div>
    </div>
  );
};
