import { Link } from "react-router-dom";

export const Links = () => {
  return (
    <>
      <Link
        to="/docs"
        style={{
          textDecoration: "underline",
          // color: "#a5bbe3",
          color: "#fefefe",
          textShadow: "0px 0px 2px #a5bbe3",
        }}
      >
        Docs
      </Link>
    </>
  );
};
