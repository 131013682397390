export const text2 = `
If you still don't have a Joule account, access djoule/register, click on "Not have an account yet ? Click here." and enter your credentials, if you do, access djoule/register: to login, after that you can download the tool you want, remember that the same login you used to logar will be used in all steps that require some kind of confirmation
`;
export const img2 = `/images/register1.png`;

export const Component2 = () => {
  return (
    <div
      style={{
        position: "absolute",
        color: "tomato",
        margin: "120px 0 0 340px",
        fontSize: "26px",
        fontWeight: "bolder",
      }}
    >
      {"<"}--- <span style={{ background: "#69362cc9" }}>If you don't</span>{" "}
      <br />
      <span style={{ background: "#69362cc9" }}>have an account</span>
    </div>
  );
};
