import { useEffect, useState } from "react";
import {
  Btnpopup,
  Headerpopup,
  Pause,
  Play,
  PopupStyle,
  Popupbtn,
  Startpopup,
  Textpopup,
} from "../styles/styles";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { makeRequest } from "../requests/makerequest";
import { toastJustError } from "../toasts/toaststyles";

export const PausesPlays = ({ pid, py }) => {
  const [states, setStates] = useState(false);

  const updateDyno = async (pid, state) => {
    try {
      //prettier-ignore
      const res = await makeRequest.put("/deploys/update-dyno/" + pid + "/" + state);
      console.log(res);
    } catch (err) {
      console.log(err);
      //prettier-ignore
      toastJustError("If you have just saved/updated your automation, wait 3 to 5 minutes to start it.");
      setStates(false);
    }
  };

  const modState = async (pid, state) => {
    setStates(!states);
    if (state === 1) {
      await updateDyno(pid, state);
    } else if (state === 0) {
      await updateDyno(pid, state);
    }
  };

  useEffect(() => {
    if (py === 1) {
      setStates(true);
    }
  }, [py]);

  return (
    <>
      {states ? (
        <>
          <Popup
            trigger={
              <Popupbtn>
                <Pause size={16} />
              </Popupbtn>
            }
            modal
            nested
            contentStyle={PopupStyle}
          >
            {(close) => (
              <Startpopup>
                <Headerpopup>Do you want to stop this automation?</Headerpopup>
                <Textpopup>
                  If you want to end an automation, no charge will be made to
                  the card that was inserted in this account, abrupt automation
                  interruptions are not counted. If you want to restart an
                  automation in the future, just click on the same button in
                  that area and follow the steps indicated.
                </Textpopup>
                <Btnpopup
                  onClick={() => {
                    modState(pid, 0);
                    close();
                  }}
                >
                  STOP
                </Btnpopup>
              </Startpopup>
            )}
          </Popup>
        </>
      ) : (
        <>
          <Popup
            trigger={
              <Popupbtn>
                <Play size={16} />
              </Popupbtn>
            }
            modal
            nested
            contentStyle={PopupStyle}
          >
            {(close) => (
              <Startpopup>
                <Headerpopup>Do you want to start this automation?</Headerpopup>
                <Textpopup>
                  If you want to start an automation, you will be charged a fee
                  of $0.99 will be charged to the card that was inserted into
                  this account once a day. If you want to close it in the
                  future, just go to this same location and follow the
                  instructions by clicking on the button.
                </Textpopup>
                <Btnpopup
                  onClick={() => {
                    modState(pid, 1);
                    close();
                  }}
                >
                  START
                </Btnpopup>
              </Startpopup>
            )}
          </Popup>
        </>
      )}
    </>
  );
};
