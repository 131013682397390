export const text13 = `
We care a lot about the performance of our customers' computers and that's why Djoule has developed a way of thinking about everyone, that's why we put the "High Performance" method with it it is possible to disable the image capture function, the reality is that most automations don't exceed 30 clicks (seriously, you need very little to do the extraordinary), but if you opt for large consistent automations and full of paths, this is a good option. A question that may arise is "But how will I see what I'm doing?" well, this is indeed a big question, but keep in mind that the images are just guidelines, good automations are made by people who believe they will make it work, so see the graphic options as an object of study and when you feel prepared (this literally takes like 2 days tops) enable the high performance function and do amazing automations.
`;

export const img13 = `/images/mechanics6.png`;
export const Component13 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-55px 0 0 150px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span
          style={{
            position: "absolute",
            color: "tomato",
            margin: "-70px 0 0 155px",
            fontSize: "21px",
            fontWeight: "bolder",
            transform: "rotate(95deg)",
            zIndex: "99",
            width: "120px",
          }}
        >
          {" "}
          {"<"}---------
        </span>

        <span style={{ background: "#69362cc9" }}>
          When this option is
          <br /> active, images are <br />
          not generated
        </span>
      </div>
    </div>
  );
};
