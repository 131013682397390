import {
  Background,
  BiMoneyWithdrawI,
  BiSolidPyramidI,
  ContentLeftWage,
  Encapsuled,
  FaBalanceScaleRightI,
  // GearI,
  // GridContainer,
  // GridItem,
  Iflash,
  ImageWages,
  LeftW,
  ParaW,
  // Rank1,
  // Rank2,
  // Rank3,
  // StyledBlobComponent,
  TextMoney,
  TextMoneyW,
  TiStarburstI,
  WagesBCont,
  WagesContainer,
  WagesContent,
  WagesInfos,
  // iconsStyleWage,
  // iconsStyleWage2,
} from "../styles/styles";
import { Sidebar } from "../sidebars/sidebar";
import { TextWages } from "./textWage";
import { TxtShuffleComponent } from "../TxtReadings/TxtShuffle";
import {
  txtDataMakeSense,
  txtDataMoneyInfo,
  txtRealIntention,
} from "../TxtReadings/txts";
// import { TiStarburst } from "react-icons/ti";

export const Wages = () => {
  return (
    <>
      {/* <StyledBlobComponent
        style={{ position: "fixed", bottom: "75%", right: "75%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "50%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}

      <Background>
        <Sidebar />
        <Iflash />
        <WagesContent>
          <LeftW>
            <ContentLeftWage>
              <ImageWages src="./images/group.png" />
              Automatize, Construa e Publique <br />
              <ParaW> Tudo isso disponível aqui!</ParaW>
            </ContentLeftWage>
          </LeftW>

          <WagesContainer>
            <WagesBCont>
              <div style={{ display: "flex" }}>
                <Encapsuled>
                  <TiStarburstI />
                  <BiSolidPyramidI />
                  <TextMoney>
                    Qual é a nossa real intenção ao mostrar isso?
                  </TextMoney>
                  <TxtShuffleComponent txt={txtRealIntention} />
                </Encapsuled>
              </div>

              <WagesInfos>
                <Encapsuled>
                  <TiStarburstI />
                  <BiMoneyWithdrawI />
                  <TextMoney>Ganhos feitos pelos membros</TextMoney>
                  <br />
                  <TextMoneyW>
                    <TextWages />
                  </TextMoneyW>

                  <TxtShuffleComponent txt={txtDataMoneyInfo} />
                </Encapsuled>

                <Encapsuled>
                  <TiStarburstI />
                  <FaBalanceScaleRightI />
                  <TextMoney>Os dados que estou vendo fazem sentido?</TextMoney>

                  <TxtShuffleComponent txt={txtDataMakeSense} />
                </Encapsuled>
              </WagesInfos>
            </WagesBCont>
          </WagesContainer>
        </WagesContent>
      </Background>
    </>
  );
};
