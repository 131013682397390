export const text12 = `
In this section you already noticed that the djoule counting mechanism is very simple to understand, to better understand see how you can change parts of your automation within the tool itself, each one controls a specific part, being the first, second and third options (immutable) and the rest being totally changeable, write corresponds to what you will write in the place of the click, so expect a text in this field, already navigate navigates to the place and despite it moving through the web, there must be a click for that , then expect a link(URL) and last but not least we have interval that expects a number, and it will control the intervals between your clicks, remember that it is not necessary to fill in all fields for all clicks, that is just one more of the benefits that the tool can provide you, so it's up to you if your automation will have extra modifications or not.
`;

export const img12 = `/images/mechanics5.png`;
export const Component12 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-115px 0 0 30px",
          fontSize: "21px",
          fontWeight: "bolder",
          //   transform: "rotate(60deg)",
        }}
      >
        {"<"}--
        <span style={{ background: "#69362cc9" }}>
          Write at the click location
          <br /> whatever you want
        </span>
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "90px 0 0 55px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}--
        <span style={{ background: "#69362cc9" }}>
          Navigate to a preferred link
        </span>
      </div>

      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "115px 0 0 45px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {" "}
        {"<"}--
        <span style={{ background: "#69362cc9" }}>Interval between clicks</span>
      </div>
    </div>
  );
};
