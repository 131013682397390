import { Formik, Form } from "formik";
import { makeRequest } from "../../components/requests/makerequest";

import * as yup from "yup";
import {
  Background,
  Container,
  Iflash,
  Formblocks,
  Formcont,
  Fields,
  Button,
  Errors,
  // StyledBlobComponent,
  ButtonNavigate,
  FooterCell,
} from "../../components/styles/styles";
import { Link } from "react-router-dom";
import {
  toastErrorResend,
  toastSuccessResend,
} from "../../components/toasts/toaststyles";
import { Sidebar } from "../../components/sidebars/sidebar";
import { Footers } from "../footers/footers";

const validationsLogin = yup.object().shape({
  email: yup.string().email("Invalid email.").required("Email is required."),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
});

export const Verifications = () => {
  const handleLogin = async (values, actions) => {
    actions.resetForm();
    await makeRequest
      .post(
        "/auth/resend-email",
        {
          email: values.email,
          password: values.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        toastSuccessResend(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        toastErrorResend(error.response.data);
      });
  };

  return (
    <>
      {/* <StyledBlobComponent
        style={{
          position: "fixed",
          bottom: "75%",
          right: "75%",
        }}
      />
      <StyledBlobComponent
        style={{
          position: "fixed",
          bottom: "50%",
          right: "25%",
        }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}

      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Formcont style={{ height: "auto" }}>
              <div>
                <h1 style={{ margin: "-5px 0 5px 0", color: "#757575" }}>
                  Resend email
                </h1>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={handleLogin}
                  validationSchema={validationsLogin}
                >
                  <Form>
                    <Formblocks>
                      <Fields name="email" placeholder="Email" />

                      <Errors
                        component="span"
                        name="email"
                        style={{
                          margin: "-25px 0 0 5px",
                          textStroke: "black",
                        }}
                      />
                    </Formblocks>

                    <Formblocks>
                      <Fields
                        name="password"
                        placeholder="Senha"
                        type="password"
                      />

                      <Errors
                        component="span"
                        name="password"
                        style={{
                          margin: "-25px 0 0 5px",
                          textStroke: "black",
                        }}
                      />
                    </Formblocks>

                    <Button className="button" type="submit">
                      Reevie confirmação
                    </Button>
                    <Link
                      to="/register"
                      style={{
                        display: "block",
                        marginLeft: "5px",
                        marginTop: "2px",
                        color: "#757575",
                        fontWeight: "bolder",
                      }}
                    >
                      <ButtonNavigate style={{ margin: "5px 0px 0px -5px" }}>
                        Volte ao login.
                      </ButtonNavigate>
                    </Link>
                  </Form>
                </Formik>
              </div>
            </Formcont>
          </div>
        </Container>
        <Footers />
        <FooterCell>
          <div style={{ color: "#c4cbda" }}>
            Automate everything with Djouleco
          </div>
        </FooterCell>
      </Background>
    </>
  );
};
