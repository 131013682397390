import { ForgotPasswords } from "../components/verifications/forgotpassword";
import { Verifications } from "../components/verifications/verifications";

export const Verification = () => {
  return (
    <>
      <Verifications />
    </>
  );
};

export const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswords />
    </>
  );
};
