import { Formik, Form } from "formik";
import { makeRequest } from "../requests/makerequest";

import * as yup from "yup";
import {
  Background,
  Container,
  Iflash,
  Formblocks,
  Formcont,
  Fields,
  Button,
  Errors,
  ButtonNavigate,
  // StyledBlobComponent,
  FooterCell,
} from "../styles/styles";
import { toastError, toastSuccess } from "../toasts/toaststyles";
import { Sidebar } from "../sidebars/sidebar";
import { Footers } from "../footers/footers";

const validationsRegister = yup.object().shape({
  email: yup.string().email("email inválido").required("Email is required."),
  username: yup
    .string()
    .min(5, "Username must be at least 5 characters long.")
    .required("O username é obrigatório"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
  confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords are different.")
    .required("The confirmation password is required."),
});

export const Cadasterstyles = ({ data }) => {
  const Shoot = () => data("dart");

  const handleRegister = async (values, actions) => {
    actions.resetForm();
    await makeRequest
      .post("/auth/register", {
        email: values.email,
        password: values.password,
        username: values.username,
      })
      .then((response) => {
        console.log(response.data);
        data("dart");
        toastSuccess(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        toastError(error.response.data, "Check your email or click here.");
      });
  };

  return (
    <>
      {/* <StyledBlobComponent
        style={{ position: "fixed", bottom: "75%", right: "75%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "50%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}

      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Formcont>
              <div>
                <h1 style={{ margin: "-5px 0 5px 0", color: "#757575" }}>
                  Register
                </h1>
                <Formik
                  initialValues={{
                    email: "",
                    username: "",
                    password: "",
                    confirmation: "",
                  }}
                  onSubmit={handleRegister}
                  validationSchema={validationsRegister}
                >
                  <Form>
                    <Formblocks>
                      <Fields name="email" placeholder="Email" />

                      <Errors
                        component="span"
                        name="email"
                        style={{
                          margin: "-25px 0 0 5px",
                          textStroke: "black",
                        }}
                      />
                    </Formblocks>

                    <Formblocks>
                      <Fields name="username" placeholder="Username" />

                      <Errors
                        component="span"
                        name="username"
                        style={{ margin: "-25px 0 0 5px" }}
                      />
                    </Formblocks>

                    <Formblocks>
                      <Fields
                        name="password"
                        placeholder="Senha"
                        type="password"
                      />

                      <Errors
                        component="span"
                        name="password"
                        style={{ margin: "-25px 0 0 5px" }}
                      />
                    </Formblocks>

                    <Formblocks>
                      <Fields
                        name="confirmation"
                        placeholder="Senha"
                        type="password"
                      />

                      <Errors
                        component="span"
                        name="confirmation"
                        style={{ margin: "-25px 0 0 5px" }}
                      />
                    </Formblocks>

                    <Button className="button" type="submit">
                      Register
                    </Button>
                    <ButtonNavigate
                      onClick={Shoot}
                      style={{ textAlign: "center" }}
                    >
                      Você já tem uma conta ? Clique aqui.
                    </ButtonNavigate>
                  </Form>
                </Formik>
              </div>
            </Formcont>
          </div>
        </Container>
        <Footers />
        <FooterCell>
          <div style={{ color: "#c4cbda" }}>
            Automate everything with Djouleco
          </div>
        </FooterCell>
      </Background>
    </>
  );
};
