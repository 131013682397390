import { useState } from "react";
import { Iconauto, Inputauto } from "../styles/styles";
import { makeRequest } from "../requests/makerequest";
import { useQueryClient } from "@tanstack/react-query";
import { toastJustError } from "../toasts/toaststyles";

export const Inputautos = () => {
  const [hash, setHash] = useState("");

  const title = "COPY-" + hash.slice(0, 5);

  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    //prettier-ignore
    if (hash.length < 64 || hash.length > 64) return console.log("Hash errado...");
    setHash("");

    const json = { clicks: "COPY", isHash: hash };

    try {
      const res = await makeRequest.post("/posts", { title });
      const postId = res.data.insertId;
      try {
        await makeRequest.post("/clicks/receber-lista?postId=" + postId, json);
        queryClient.invalidateQueries(["click"]);
      } catch (err) {
        console.log(err);
        toastJustError("The hash is wrong, check it");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Inputauto
        type="text"
        placeholder="When you have a key enter it here..."
        onChange={(e) => setHash(e.target.value)}
        value={hash}
      />
      <Iconauto size={24} arg={hash.length} onClick={handleSubmit} />
    </form>
  );
};
