export const text6 = `
In this section you can find how to better conduct your fields for automations, interpret this area as an intuitive place for your best ideas, apparently it is empty but soon it will have one of the best automations in the world, that's for sure!
`;
export const img6 = `/images/djouleAppearance3.png`;
export const Component6 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-220px 0 0 -220px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          When you create an automation field <br />
          and click on it it opens here
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-60px 0 0 -290px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          This is where you click to open the automation field
        </span>
      </div>
    </div>
  );
};
