import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { Home } from "./pages/home";
import { Register } from "./pages/register";
import { Products } from "./pages/products";
import { Doc } from "./pages/doc";
import { Verification } from "./pages/verification";
import { ForgotPassword } from "./pages/verification";
import { GlobalStyle } from "./components/styles/styles";
import { ChangePasswordComponent } from "./components/verifications/forgotpassword";
import { Wage } from "./pages/wage";
import { PaymentAdd } from "./pages/paymentadd";
import { Workspace } from "./pages/workspace";
import { View } from "./pages/view";
import { PaymentAddWithSidebar } from "./pages/paymentaddwithsidebar";

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/products" element={<Products />}></Route>
          <Route path="/docs" element={<Doc />}></Route>
          <Route path="/verifications" element={<Verification />}></Route>
          <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route path="/changepassword" element={<ChangePasswordComponent />} />
          <Route path="/wages" element={<Wage />} />
          <Route path="/paymentadd" element={<PaymentAdd />} />
          <Route path="/paymentsadds" element={<PaymentAddWithSidebar />} />
          <Route path="/workspace" element={<Workspace />} />
          <Route path="/workspace/:pid" element={<View />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
