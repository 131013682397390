import styled from "styled-components";
// import { Field, ErrorMessage } from "formik";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 90px;
  position: relative;
  margin-top: 80px;
`;

export const Rigth = styled.div``;

export const NoStripeInputW = styled.div`
  display: grid;

  @media (max-width: 926px) {
    width: 375px;
    margin-left: 25px;
  }
`;

export const Spaceflex = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const CardContainer = styled.div`
  perspective: 1000px;
  /* position: fixed; */
  /* width: 400px; */
  height: 240px;
`;

export const Card = styled.div`
  position: fixed;
  width: 400px;
  height: 240px;
  margin-top: -70px;
  margin-left: 50px;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  transform: ${({ stateRotate }) =>
    stateRotate
      ? "perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg)"
      : ""};
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: 926px) {
  }
`;

export const Front = styled.div`
  position: fixed;
  width: 360px;
  height: 240px;
  background-image: url(/images/img-2.png);
  background-size: cover;
  backface-visibility: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  @media (max-width: 926px) {
    padding: 5.5px 8px 0px 8px;
  }
`;

export const Back = styled.div`
  position: absolute;
  width: 400px;
  height: 240px;
  background-image: url(/images/img-2.png);
  background-size: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding-top: 20px;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

export const CardCvv = styled.div`
  height: 50px;
  background-color: #00000088;
  width: 100%;
`;

export const CVV = styled.div`
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: -15px;
  margin-top: 15px;
  color: #fefefe;
`;

export const WhiteLine = styled.div`
  margin: 20px;
  padding: 20px;
  height: 20px;
  background: white;
  border-radius: 5px;
`;

export const Hashcont = styled.div`
  display: flex;
  align-items: center;
  height: 50%;
  font-size: 22px;
  justify-content: space-between;
  text-shadow: 7px 6px 10px rgba(7, 16, 31, 0.8);
`;

export const Citem = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #fefefea0;
  text-shadow: 7px 6px 10px rgba(7, 16, 31, 0.8);
  font-family: Consolas;
`;

export const Citemm = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin-top: 2px;
  text-shadow: 7px 6px 10px rgba(7, 16, 31, 0.8);
  color: white;
  font-family: Consolas;
`;

export const Spanr = styled.span`
  color: white;
  font-family: sans-serif;
`;

export const Bbox = styled.div`
  display: flex;
  justify-content: center;
  background-color: #191919;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 500px;
  height: auto;
  padding-top: 190px;
`;

export const Lil = styled.li`
  font-size: 12px;
  list-style: none;
  color: darkgray;
  margin-left: 3px;
  font-family: sans-serif;
  margin-bottom: 1px;
`;

export const Lils = styled.li`
  font-size: 12px;
  list-style: none;
  color: darkgray;
  margin-left: 3px;
  font-family: sans-serif;
  margin-bottom: 1px;
  @media (max-width: 926px) {
    margin-left: 25px;
  }
`;

export const Lilss = styled.li`
  font-size: 12px;
  list-style: none;
  color: darkgray;
  margin-left: 3px;
  font-family: sans-serif;
  margin-bottom: 1px;
  @media (max-width: 926px) {
    margin-right: 35px;
  }
`;

export const Date = styled.div`
  display: grid;
`;

export const Flex = styled.div`
  display: flex;
  margin-top: -8px;
  justify-content: space-between;
`;

export const Submit = styled.button`
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #333333;
  color: darkgray;
  font-size: 22px;
  margin-bottom: 20px;
  transition: all 300ms;
  font-family: sans-serif;
  @media (max-width: 926px) {
    width: 375px;
    margin-left: 23px;
  }

  &:hover {
    background-color: #191919;
    color: darkgray;
    border: 1px solid darkgray;
    cursor: pointer;
    transition: all 300ms;
  }
`;

export const Dates = styled.div``;

export const DatesS = styled.div`
  display: grid;
  margin-top: 10px;

  @media (max-width: 926px) {
    width: 210px;
  }
`;

// export const Fieldate = styled(Field)`
//   width: 80px;
//   padding-left: 5px;
//   font-size: 18px;
//   border-radius: 5px;
//   border: none;
//   height: 40px;
//   background-color: #333333;
//   color: darkgray;

//   &::-webkit-scrollbar {
//     width: 5px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: #5c5c5c;
//   }
//   &::-webkit-scrollbar-thumb:hover {
//     background-color: #999;
//   }
// `;

export const ErrorMsg = styled.span`
  font-size: 12px;
  color: tomato;
  font-family: sans-serif;
  margin-left: 3px;
  margin-top: -5px;
`;

export const FormGroup = styled.fieldset`
  height: 50px;
  padding: 0;
  background-color: #333333;
  will-change: opacity, transform;
  border-radius: 4px;
  border: none;
  margin-bottom: 10px;
`;

export const FormOtherGroup = styled.fieldset`
  height: 50px;
  padding: 0;
  background-color: #333333;
  will-change: opacity, transform;
  border-radius: 4px;
  border: none;
  margin-bottom: 10px;
  width: 220px;
  margin-right: 20px;

  @media (max-width: 926px) {
    width: 165px;
    margin-right: 20px;
    margin-left: 25px;
  }
`;

export const FormRow = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    -webkit-text-fill-color: #333;
  }
  padding-left: 5px;

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }
`;

export const NameCardContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 10px 0 0 10px;
  padding: 0 5px 0 0;
  background: #232323;
  border-radius: 5px;
  width: calc(100% - 20px);
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid darkgray;
  transition: 300ms all ease;
  justify-content: space-between;

  &:hover {
    background-color: #212121;
    transition: 300ms all ease;
    cursor: pointer;
  }
`;
export const CardLi = styled.li`
  list-style: none;
  font-size: 26px;
  margin-left: 10px;
  color: darkgray;
  display: flex;
  justify-content: center;

  &:nth-child(2) {
    border-radius: 100%;
    padding: 5px;
    transition: all 300ms ease;

    &:hover {
      background-color: #75757589;
      transition: all 300ms ease;
    }
  }
`;

export const CardLiTrue = styled.li`
  list-style: none;
  font-size: 26px;
  margin-left: 10px;
  color: darkgray;
  display: flex;
  justify-content: center;
  border-radius: 0 0 0 5px;
  transition: all 300ms ease;

  &:hover {
    background-color: #75757589;
    cursor: pointer;
    transition: all 300ms ease;
  }
`;

export const Contpay = styled.div`
  background-color: #1a1a1aae;
  width: calc(100% - 100px);
  height: calc(100% - 220px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  position: fixed;
  margin-top: -20px;
  border: 1px solid #a5bbe3;
  @media (max-width: 926px) {
    height: calc(100% - 110px);
    margin-top: 0px;
    margin-bottom: -80px;
    width: calc(100%);
  }
`;
