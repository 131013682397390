import Popup from "reactjs-popup";
import { makeRequest } from "../requests/makerequest";
import {
  AddCard,
  Btnpopup,
  Headerpopup,
  PopupStyle,
  Popupbtn,
  Startpopup,
  Textpopup,
} from "../styles/styles";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toastJustError } from "../toasts/toaststyles";

export const ConfirmAddPays = ({ pid }) => {
  const [dataStripe, setDataStripe] = useState([]);

  const { isLoading, error, data } = useQuery(["useDataStripe"], () =>
    makeRequest.post("/payments/data-stripe").then((res) => {
      return res.data;
    })
  );

  useEffect(() => {
    setDataStripe(error ? "error" : isLoading ? "Is loading..." : data);
  }, [isLoading, error, data]);

  const queryClient = useQueryClient();

  const addMethodPay = async (cardNickNameId) => {
    try {
      await makeRequest.post("/payments/sign-payment-plan", {
        postId: pid,
        cardNickNameId: cardNickNameId,
      });
      queryClient.invalidateQueries(["click"]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup
        trigger={
          <Popupbtn>
            <AddCard size={22} />
          </Popupbtn>
        }
        modal
        nested
        contentStyle={PopupStyle}
      >
        {(close) => (
          <Startpopup>
            <Headerpopup>Do you want to confirm this automation?</Headerpopup>
            <Textpopup>
              Click on the button to authorize this section, this action will
              not charge you anything, you will be able to control any expenses
              managed by you in this same area through an off and on button
              located here.
            </Textpopup>
            <Btnpopup
              onClick={() => {
                try {
                  addMethodPay(dataStripe[0].id);
                } catch (err) {
                  toastJustError(
                    "You need to add a credit card to confirm this section."
                  );
                }

                close();
              }}
            >
              ADD
            </Btnpopup>
          </Startpopup>
        )}
      </Popup>
    </>
  );
};
