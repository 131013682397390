import { BiMoneyWithdraw, BiSolidPyramid } from "react-icons/bi";
import styled, { createGlobalStyle } from "styled-components";
import { MdOutlineAddCard, MdSaveAlt } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { VscTerminalLinux } from "react-icons/vsc";
import { HiOutlineSaveAs } from "react-icons/hi";
import { FaTrashCan } from "react-icons/fa6";
import { Field, ErrorMessage } from "formik";
import { TiStarburst } from "react-icons/ti";
import { AiFillEye } from "react-icons/ai";
import { IoFlash } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaAngleDoubleUp,
  FaWindows,
  FaApple,
  FaPause,
  FaPlay,
  FaBalanceScaleRight,
} from "react-icons/fa";

export const Background = styled.div`
  background-size: cover;
  background-position: center;
  height: 100vh;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Sibebar = styled.div`
  background-color: #232323ca;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  position: absolute;

  top: ${({ active }) => (active ? "0px" : "-250px")};
  transition: all 300ms ease;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const ListContainer = styled.ul`
  position: absolute;
  margin-top: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const ListItem = styled.li`
  color: white;
  font-size: 18px;
  list-style: none;
  font-size: 24px;
  padding: 11px;
  text-align: center;

  &:hover {
    background-color: #23232390;
    cursor: pointer;
    transition: all 300ms;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const To = styled(Link)`
  color: white;
  font-size: 18px;
  list-style: none;
  font-size: 24px;
  text-decoration: none;

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Header = styled.header`
  background-color: #232323ca;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Bars = styled(FaBars)`
  color: #fefefee1;
  margin-left: 15px;
  font-size: 25px;
  display: ${({ active }) => (active ? "none" : "")};

  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Close = styled(FaAngleDoubleUp)`
  color: #fefefee1;
  margin-left: 15px;
  font-size: 25px;
  position: absolute;
  top: 0;
  margin-top: 12px;

  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Chartwohome = styled.img`
  object-fit: cover;
  object-position: center center;
  max-width: 43vh;
  max-height: 43vh;
  width: auto;
  height: auto;
  aspect-ratio: 1;

  &:nth-child(2) {
    rotate: 90deg;
    position: relative;
    margin-left: 40px;
  }

  @media screen and (min-width: 320px) and (max-width: 1200px) {
    &:nth-child(1) {
      max-width: 50vh;
      max-height: 50vh;
    }

    &:nth-child(2) {
      display: none;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 480px) {
    &:nth-child(1) {
      width: 280px;
      height: 280px;
      margin-top: 20px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #00000088;
  height: 60vh;

  &:nth-child(even) {
    display: flex;
    padding: 50px;
    align-items: center;
    justify-content: space-between;
    background-color: #00000088;
    height: 60vh;
    direction: rtl;
  }

  &:nth-child(even) ${Chartwohome}:nth-child(2n) {
    transform: rotate(90deg);
    position: relative;
    margin: 0;
  }

  &:nth-child(even) ${Chartwohome}:nth-child(4n + 2) {
    transform: rotate(90deg);
    position: relative;
    margin-right: 70px;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }

  @media screen and (min-width: 320px) and (max-width: 480px) {
    display: flex;
    padding: 20px;
    flex-direction: column;
    background-color: #00000088;
    height: 65vh;
    position: relative;
  }
`;

export const Text = styled.h1`
  font-size: 68px;
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 10px;
  background: linear-gradient(45deg, var(--blob), var(--background));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: fadeIn 3s linear infinite;
  text-shadow: 0px 0px 1px #444d5865;

  @media (max-width: 844px) {
    font-size: 48px;
    font-family: "Roboto", sans-serif;
  }
`;

export const TextMoney = styled.h1`
  text-align: center;
  position: relative;
  display: inline-block;
  color: #f3f3f3;
  text-shadow: 0px 0px 1px #444d5865;
  font-size: 24px;
  margin: 0;
  padding-bottom: 25px;
  text-align: justify;

  @media (max-width: 844px) {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #fefefe;
  }
`;

export const TextMoneyW = styled.h1`
  text-align: center;
  position: relative;
  display: inline-block;
  color: #f3f3f3;
  text-shadow: 0px 0px 1px #444d5865;
  font-size: 24px;
  margin: -20px 0 10px 0;
  padding-bottom: 20px;

  @media (max-width: 844px) {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #fefefe;
  }
`;

export const Span = styled.div`
  font-weight: bolder;
  text-align: center;
  font-size: 28px;
  white-space: nowrap;
  color: #fefefe;

  margin-bottom: 10px;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Input = styled(Link)`
  background: #232323;
  color: #757575;
  text-align: center;
  width: 550px;
  height: 45px;
  margin-top: 10px;
  outline: none;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bolder;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media (max-width: 844px) {
    width: 350px;
    font-family: "Roboto", sans-serif;
  }
`;

export const Iflash = styled(IoFlash)`
  color: #232323;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 10px;
  font-size: 22px;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const WagesContent = styled.div`
  display: flex;
  max-height: 100vh;

  @media (max-width: 1044px) {
    display: block;
  }
`;

export const Windows = styled(FaWindows)`
  object-position: center;
  object-fit: cover;
  width: 42vh;
  height: 42vh;
  color: #fefefe;
  transition: all 300ms ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 300ms ease;
  }

  &:nth-child(2) {
    rotate: 90deg;
    position: relative;
    margin-left: 70px;
  }

  @media screen and (min-width: 320px) and (max-width: 1700px) {
    &:nth-child(2) {
      display: none;
    }

    @media screen and (min-width: 320px) and (max-width: 480px) {
      &:nth-child(1) {
        width: 280px;
        height: 280px;
        margin-top: 20px;
      }
    }
  }
`;

export const Apple = styled(FaApple)`
  object-position: center;
  object-fit: cover;
  width: 42vh;
  height: 42vh;
  color: #fefefe;
  transition: all 300ms ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 300ms ease;
  }

  &:nth-child(2) {
    rotate: 90deg;
    position: relative;
    margin-left: 70px;
  }

  @media screen and (min-width: 320px) and (max-width: 1700px) {
    &:nth-child(2) {
      display: none;
    }

    @media screen and (min-width: 320px) and (max-width: 480px) {
      &:nth-child(1) {
        width: 280px;
        height: 280px;
        margin-top: 20px;
      }
    }
  }
`;

export const Linux = styled(VscTerminalLinux)`
  object-position: center;
  object-fit: cover;
  width: 42vh;
  height: 42vh;
  color: #fefefe;
  transition: all 300ms ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 300ms ease;
  }

  &:nth-child(2) {
    rotate: 90deg;
    position: relative;
    margin-left: 70px;
  }

  @media screen and (min-width: 320px) and (max-width: 1700px) {
    &:nth-child(2) {
      display: none;
    }

    @media screen and (min-width: 320px) and (max-width: 480px) {
      &:nth-child(1) {
        width: 280px;
        height: 280px;
        margin-top: 20px;
      }
    }
  }
`;

export const ContainerIcons = styled.div`
  display: flex;

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const ContentIcons = styled.div`
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Formcont = styled.div`
  background-color: #232323;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  width: 400px;
  height: auto;
  padding: 25px;
  border-radius: 15px;

  @media (max-width: 844px) {
    width: 300px;
    font-family: "Roboto", sans-serif;
  }
`;

export const Formblocks = styled.div`
  display: grid;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Fields = styled(Field)`
  height: 35px;
  font-size: 17px;
  margin-bottom: 25px;
  border: none;
  background-color: #303030;
  outline: none;
  color: #646464;
  font-weight: bolder;
  border-radius: 10px;
  padding-left: 10px;

  ::placeholder {
    color: #646464;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Errors = styled(ErrorMessage)`
  color: tomato;
  font-size: 17px;
  font-weight: bolder;

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 35px;
  border: none;
  font-size: 17px;
  font-weight: bolder;
  color: #757575;
  transition: all 300ms ease;
  border-radius: 10px;
  background: #303030;

  &:hover {
    background-color: #fefefe;
    cursor: pointer;
    transition: all 300ms ease;
    color: #232323;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Codebox = styled.div`
  width: 900px;
  min-height: 500px;
  background-color: #1a1a1aae;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px #1a1a1a;
  margin-bottom: 50px;
  border: 1px solid #fefefe;
  margin-bottom: ${({ index, docsdatalen }) =>
    index === docsdatalen - 1 ? "100px" : ""};

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Codeboxtop = styled.div`
  background-image: linear-gradient(#1a1a1a, transparent);
  border-radius: 15px 15px 0 0;
  width: 100%;
  display: flex;
  height: 20px;
  align-items: center;

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Coloredballs = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-left: 10px;

  &:nth-child(1) {
    background-color: #dedfe0;
  }
  &:nth-child(2) {
    background-color: #a5a5a5;
  }
  &:nth-child(3) {
    background-color: #747474;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const ButtonNavigate = styled.button`
  margin: 10px 0 0 10px;
  color: rgba(254, 254, 254);
  font-size: 15px;
  font-weight: bolder;
  text-decoration: underline;
  background: transparent;
  border: none;
  color: #757575;

  &:hover {
    background: #fefefe47;
    cursor: pointer;
    color: #ffffff;
  }

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Txtlime = styled.div`
  color: #fefefe;
  text-shadow: 0px 0px 2px #fefefe;
  margin-top: -20px;
  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const TxtProduct = styled.div`
  color: #fefefe;

  @media (max-width: 844px) {
    font-family: "Roboto", sans-serif;
  }
`;

export const Txtlimes = styled.div`
  color: #fefefe;
  @media (max-width: 844px) {
    color: #c4cbda;
    font-family: "Roboto", sans-serif;
  }
`;

export const BackgroundAuto = styled.div`
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.04) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.04) 1px, transparent 1px);
  background-size: 4px;
`;

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #fff;
    --blob: #fdfbfd;
    --shades: inset 10px 0 40px #f7f8fc,
              inset -10px 0 20px #f7e1ef,
              inset -40px 10px 100px #c3c5ea;
    
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --background: #0f0c24;
      --blob: #a5bbe3;
      --shades: inset 10px 0 40px #74addb,
                inset -10px 0 20px #24365e,
                inset -40px 10px 110px #163148;
    }
  }

   @media (max-width: 844px) {
    body {
      font-family: 'Roboto', sans-serif;
    }
  }
`;

export const StyledBlobComponent = styled.div`
  &::before {
    content: "";
    display: block;
    min-height: 200px;
    min-width: 200px;
    width: 60vh;
    height: 60vh;
    background-color: var(--blob);
    box-shadow: var(--shades);
    background-size: 1600% 1600%;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    perspective: 1000px;
    animation: transform 50s ease-in-out infinite both alternate,
      movement 40s ease-in-out infinite both;
    position: absolute;
  }

  @keyframes transform {
    0%,
    100% {
      border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
      border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
      border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
      border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
      border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
      border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
      border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
  }

  @media (max-width: 844px) {
    &::before {
      content: "";
      display: block;
      min-height: 200px;
      min-width: 200px;
      width: 40vh;
      height: 40vh;
      background-color: var(--blob);
      box-shadow: var(--shades);
      background-size: 1600% 1600%;
      transform-origin: 50% 50%;
      transform-style: preserve-3d;
      perspective: 1000px;
      animation: transform 50s ease-in-out infinite both alternate,
        movement 40s ease-in-out infinite both;
      position: absolute;
      top: 30px;
    }

    &:nth-child(2) {
      margin-bottom: 60px;
    }
    &:nth-child(3) {
      margin-bottom: -160px;
    }
    &:nth-child(4) {
      margin-left: -100px;
    }
    &:nth-child(6) {
      margin-left: -70px;
    }
  }
`;

export const WagesContainer = styled.div`
  width: 50vw;
  height: calc(100vh - 94px);

  @media (max-width: 1044px) {
    width: auto;
  }
`;

export const WagesBCont = styled.div`
  padding: 20px;
  height: calc(100vh - 134px);
  overflow-y: scroll;
`;

export const LeftW = styled.div`
  background-color: #171717;
  width: 50vw;
  height: calc(100vh - 94px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1044px) {
    width: 100%;
  }
`;

export const ContentLeftWage = styled.div`
  padding: 50px;
  text-align: center;
  font-size: 2.2rem;
  color: #fefefe;
  display: grid;
`;

export const ImageWages = styled.img`
  width: 100%;
  height: auto;

  max-width: 950px;
`;

export const ParaW = styled.p`
  font-size: 1.4rem;
`;

export const DataWagesContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const DataWageBox = styled.div`
  border-radius: 10px;
  margin: 30px;
  display: grid;
`;

export const Rank1 = styled.div`
  width: 600px;
  text-align: center;
  margin: 0 50px 0 50px;
  background-color: #1a1a1aae;
  border-radius: 15px 15px 0 0;
  padding: 50px;
  position: relative;
  z-index: 1;
  text-align: justify;
  height: 400px;
  border-top: 1px solid #fefefe;
  border-right: 1px solid #fefefe;
  border-left: 1px solid #fefefe;

  @media (max-width: 1564px) {
    width: 300px;
    margin-right: 0;
  }

  @media (max-width: 926px) {
    position: absolute;
    background-color: transparent;
    z-index: -9999;
    border: none;
  }
`;

export const Download1 = styled.div`
  text-align: center;
  background-color: #1a1a1aae;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  text-align: justify;
  border: 1px solid #fefefe;
  width: 100%;

  @media (max-width: 1564px) {
    margin-right: 0;
  }
`;

export const Download = styled.div`
  padding: 35px;
`;

export const Rank2 = styled.div`
  width: 600px;
  text-align: center;
  margin: 0 50px 0 50px;
  background-color: #1a1a1aae;
  position: fixed;
  height: 100%;
  top: 200px;
  border-radius: 15px 15px 0 0;
  padding: 50px;
  border-top: 1px solid #a5bbe3;
  border-right: 1px solid #a5bbe3;
  border-left: 1px solid #a5bbe3;
  @media (max-width: 844px) {
    border-top: 1px solid #c6c8ca;
    border-right: 1px solid #c6c8ca;
    border-left: 1px solid #c6c8ca;
    font-family: "Roboto", sans-serif;
    width: 200px;
  }
`;

export const Rank3 = styled.div`
  width: 600px;
  text-align: center;
  margin: 0 50px 0 50px;
  background-color: #1a1a1aae;
  border-radius: 15px 15px 0 0;
  padding: 50px;
  position: relative;
  z-index: 1;
  text-align: justify;
  height: 400px;
  border-top: 1px solid #a5bbe3;
  border-right: 1px solid #a5bbe3;
  border-left: 1px solid #a5bbe3;

  @media (max-width: 1564px) {
    width: 300px;
  }

  @media (max-width: 844px) {
    display: none;
  }
`;

export const RankD = styled.div`
  width: 370px;
  text-align: center;
  margin: 0 50px 0 50px;
  background-color: #1a1a1aae;
  border-radius: 15px 15px 0 0;
  padding: 50px;
  position: relative;
  z-index: 1;
  text-align: justify;
  height: 400px;
  border-top: 1px solid #fefefe;
  border-right: 1px solid #fefefe;
  border-left: 1px solid #fefefe;
  display: flex;

  @media (max-width: 1706px) {
    max-width: 220px;
  }
  @media (max-width: 1606px) {
    max-width: 160px;
  }
  @media (max-width: 1506px) {
    max-width: 100px;
  }
  @media (max-width: 1406px) {
    max-width: 80px;
  }
  @media (max-width: 1306px) {
    display: none;
  }
`;

export const iconsStyleWage = {};

export const TiStarburstI = styled(TiStarburst)`
  position: absolute;
  top: -15px;
  left: -15px;
  font-size: 42px;
  color: #f3f3f3;
`;

export const BiSolidPyramidI = styled(BiSolidPyramid)`
  position: absolute;
  top: -2px;
  left: -2px;
  color: #232323;
`;

export const BiMoneyWithdrawI = styled(BiMoneyWithdraw)`
  position: absolute;
  top: -2px;
  left: -2px;
  color: #232323;
`;

export const FaBalanceScaleRightI = styled(FaBalanceScaleRight)`
  position: absolute;
  top: -2px;
  left: -2px;
  color: #232323;
`;

export const iconsStyleWageAuto = {
  position: "absolute",
  top: "-15px",
  right: "-15px",
};

export const iconsStyleWageAuto2 = {
  position: "absolute",
  top: "-2px",
  right: "-2px",
  color: "#232323",
};

export const Lisearch = styled.li`
  list-style: none;
  text-decoration: underline;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    color: #c0c0c0;
  }
`;

export const ContainerDocs = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: auto;
  display: grid;
  margin-top: 5vh;
`;

export const ContentDocs = styled.div`
  width: calc(100% - 30px);
  height: 445px;
  background-size: cover;
  background-position: center;
  background-image: ${({ img }) => `url(${img})`};
  margin: -10px 15px 15px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpanText = styled.span`
  @media (max-width: 926px) {
    display: none;
  }
`;

export const WorkspaceDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1a1a1aae;
  height: calc(100% - 180px);
  margin-top: 25px;
  border: 1px solid #fefefe;
  border-radius: 15px;
  width: calc(100% - 100px);
  position: relative;

  @media (max-width: 844px) {
    height: calc(90% - 10px);
    width: calc(100% - 20px);

    margin-top: 80px;
    flex-direction: column;
  }
`;

export const CentralizeSp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Leftbox = styled.div`
  background-color: #1a1a1aae;
  width: calc(70% - 20px);
  margin: 10px;
  overflow-x: auto;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #fefefe;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  @media (max-width: 844px) {
    width: calc(100% - 22px);
    height: calc(100% - 0px);
  }
`;

export const Rightbox = styled.div`
  background-color: #1a1a1aae;
  height: calc(100% - 20px);
  width: calc(30% - 20px);
  margin: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #fefefe;
  padding: 10px;
  overflow-wrap: break-word;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 844px) {
    display: none;
  }
`;

export const Titles = styled.div`
  width: calc(100% - 40px);
  height: 40px;
  /* position: absolute; */
  margin: 10px;
  border-radius: 10px;
  border: none;
  background-color: #232323;
  box-shadow: 2px 2px 5px #00000030;
  color: #808080;
  font-size: 16px;
  font-weight: bolder;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;

  &::placeholder {
    color: #808080;
    font-size: 16px;
    font-weight: bolder;
    padding-left: 5px;
  }
`;

export const Eye = styled(AiFillEye)`
  margin-left: 8px;
  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }
`;

export const Trash = styled(FaTrashCan)`
  margin-left: 0px;
  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }
`;

export const Pause = styled(FaPause)`
  margin-left: 8px;
  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }
`;

export const Play = styled(FaPlay)`
  margin-left: 8px;
  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }
`;

export const Table = styled.table`
  margin-top: 35px;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  transition: all 300ms ease;

  td:nth-child(1),
  th:nth-child(1) {
    color: rgb(76, 134, 207);
    font-weight: bold;
  }

  td,
  th {
    border: 1px solid #a9a9a9b2;
    text-align: left;
    padding: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 30px;
    color: rgb(76, 134, 207);
    padding-left: 5px;
  }

  tr:nth-child(even) {
    background-color: #757575b0;
    color: #232323;
  }
`;

export const Inputtab = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: bolder;
  outline: none;
  color: darkgray;
`;

export const InputtabCp = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: bolder;
  outline: none;
  color: ${({ value }) =>
    value === 1 ? "#f9e076" : value === 2 ? "#6fc276" : "darkgray"};
`;

export const Buttontb = styled.button`
  background: #232323;
  border: none;
  color: darkgray;
  border-radius: 7px;
  height: 22px;
  width: 48px;
  font-weight: bolder;
  font-size: 16px;
  align-content: center;
  display: flex;
  align-items: center;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    color: #bdbdbd;
  }
`;

export const Spbtexts = styled.div`
  position: absolute;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Spbcont = styled.div`
  position: relative;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  top: -18px;
  padding: 10px;
`;

export const Inputauto = styled.input`
  width: calc(100% - 25px);
  height: 40px;
  position: absolute;
  margin: 10px;
  border-radius: 10px;
  border: none;
  background-color: #232323;
  box-shadow: 2px 2px 5px #00000030;
  color: #808080;
  font-size: 16px;
  font-weight: bolder;
  outline: none;

  &::placeholder {
    color: #808080;
    font-size: 16px;
    font-weight: bolder;
    padding-left: 5px;
  }

  @media (max-width: 844px) {
    &::placeholder {
      font-family: "Roboto", sans-serif;
      font-size: small;
    }
  }
`;

export const Iconauto = styled(MdSaveAlt)`
  position: absolute;
  right: 0;
  margin: 12px;
  background-color: #232323;
  border-radius: 0px 10px 10px 0px;
  padding: 7px;

  color: ${({ arg }) =>
    arg === 0 || arg < 64 || arg > 64 ? "#313131" : "gray"};

  &:hover {
    color: ${({ arg }) =>
      arg === 0 || arg < 64 || arg > 64 ? "#313131" : "darkgray"};
    cursor: ${({ arg }) =>
      arg === 0 || arg < 64 || arg > 64 ? "not-allowed" : "pointer"};
  }
`;

export const Popupbtn = styled.button`
  background: transparent;
  border: none;
  color: gray;
  outline: none;
  display: flex;
`;

export const Startpopup = styled.div`
  background-color: #232323;
  max-width: 500px;
  padding: 10px;
  color: darkgray;
  font-weight: bolder;
  border-radius: 10px;
`;

export const Headerpopup = styled.div`
  margin-bottom: 15px;
  font-size: 20px;
`;

export const Textpopup = styled.div`
  margin-bottom: 15px;
  text-align: justify;
`;

export const Btnpopup = styled.button`
  width: 100%;
  border: none;
  height: 30px;
  font-size: 18px;
  font-weight: bolder;
  background-color: darkgray;
  color: #232323;
  transition: 300ms ease all;
  border-radius: 5px;

  &:hover {
    background-color: #232323;
    color: darkgray;
    border: 1px solid darkgray;
    transition: 300ms ease all;
    cursor: pointer;
  }
`;

export const PopupStyle = {
  padding: "0",
  maxWidth: "500px",
  border: "none",
  borderRadius: "15px",
};

export const AddCard = styled(MdOutlineAddCard)`
  margin-left: 6px;
  margin-right: -4px;

  &:hover {
    color: #cacacae0;
    cursor: pointer;
  }
`;

export const Dedicated = styled.p`
  color: darkgray;

  @media (max-width: 844px) {
    font-size: 13px;
    margin-bottom: 18px;
  }
`;

export const IconContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SaveAll = styled(HiOutlineSaveAs)`
  margin-left: 12px;
  color: ${({ act }) => (act === 1 ? "" : "#333333")};

  &:hover {
    color: ${({ act }) => (act !== 1 ? "" : "#cacacae0")};
    cursor: ${({ act }) => (act !== 1 ? "not-allowed" : "pointer")};
  }
`;

export const ContVideo = styled.div`
  position: fixed;
  display: none;
  @media (max-width: 844px) {
    display: block;
  }
`;

export const MoneyLink = styled(Link)`
  color: #fefefe;
  font-weight: bolder;
  text-decoration: none;
  font-size: 16px;
`;

export const FooterCell = styled.div`
  position: fixed;
  bottom: 0;
  color: #fefefe;
  z-index: 5;
  border: 1px solid #fefefe;
  background-color: #232323d1;
  width: calc(100% - 50px);
  margin-left: 25px;
  border-radius: 5px 5px 0 0;
  border-bottom: 0px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  display: none;
  @media (max-width: 844px) {
    display: flex;
  }
`;

export const Encapsuled = styled.div`
  background-color: #232323;
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 10px;
  text-align: justify;
  width: 100%;

  &:nth-child(2) {
    margin-left: 30px;
    height: auto;
  }

  &:nth-child(3) {
    margin-left: 30px;
  }

  @media (min-width: 100px) and (max-width: 800px) {
    &:nth-child(2) {
      margin: 0px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 100%;
`;

export const GridItem = styled.div`
  width: 100%;
`;

export const WagesInfos = styled.div`
  display: block;
  height: 100%;

  @media (min-width: 727px) {
    display: flex;
    height: auto;
    height: 100%;
  }
`;

export const SidebarNavs = styled.nav`
  background-color: #3f3f3f88;
  padding: 10px 20px 10px 20px;

  ul {
    padding: 0px;
    margin: 0px;

    li {
      list-style: none;
    }
  }
`;

export const DjoulecoLogo = styled.img`
  width: 70px;
  height: 70px;
`;

export const SidebarUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SidebarLinks = styled.div`
  display: flex;

  li {
    margin-left: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: #fefefe;
      text-decoration: none;
    }
  }
`;

export const AuthLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export const TryLink = styled(Link)`
  border: 1px solid #fefefe;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;

  &:hover {
    background-color: #fefefe;
    color: #000;
    transition: all 300ms ease;
  }
`;

export const AuthArrow = styled(FaArrowRightLong)`
  font-size: 16px;
  margin-left: 5px;
  transform: rotate(-45deg);
`;

export const ContentHome = styled.div`
  color: #fefefe;
`;

export const Bloguehome = styled.p`
  margin-bottom: -20px;

  @media screen and (min-width: 320px) and (max-width: 480px) {
    margin-bottom: -5px;
    font-size: 12px;
  }
`;

export const HomeH1 = styled.h1`
  font-size: 48px;

  @media screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HomeH2 = styled.h2`
  width: 40vw;
  font-size: 22px;
  margin-top: -20px;
  margin-bottom: 40px;

  @media screen and (min-width: 320px) and (max-width: 480px) {
    width: 70vw;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 20px;
  }
`;

export const AboutContainerhome = styled.div`
  margin-top: 15px;

  @media screen and (min-width: 320px) and (max-width: 480px) {
    margin-top: 15px;
    font-size: 12px;
  }
`;

export const AboutContent = styled(Link)`
  margin-right: 10px;
  padding: 5px;
  color: #fefefe;

  &:nth-child(1) {
    border: 1px solid #fefefe;
    border-radius: 5px;
    transition: 300ms ease-out;
    color: #fefefe;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &:nth-child(1):hover {
    border: 1px solid transparent;
    background-color: #fefefe;
    color: black;
    cursor: pointer;
    transition: 300ms ease-out;
  }

  &:nth-child(2):hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Arrow = styled.i`
  rotate: -45deg;
  position: relative;
`;

export const MoneyHome = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 30px;
`;

export const HomeLine = styled.div`
  border-bottom: 1px solid #fefefe;
  width: 70%;
  height: 2px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const InfoProduc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;

export const AboutContainerProd = styled.div``;
