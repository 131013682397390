import { HiCursorClick } from "react-icons/hi";
import { RiShieldFlashFill } from "react-icons/ri";
import { FaStore, FaGear } from "react-icons/fa6";
import { BiMoneyWithdraw } from "react-icons/bi";

export const text1 = `
 It is with great pleasure that we welcome you all to our new tool, Djoule. This application has been carefully designed and developed to simplify and 
    improve the processes that are part of your daily life. Whether complex processes or repetitive tasks, Djoule is here to optimize and streamline each 
    step. Our team has worked tirelessly to create a solution that meets the varied and demanding needs of the contemporary world. The name "Djoule" stands 
    for a unit of measurement of energy, and that's what our application stands for: a source of energy for processes that used to be time consuming and exhausting. 
    By using Djoule, you will have the opportunity to dedicate more time to what really matters, be it growing your business, improving your skills or simply 
    enjoying moments of tranquility. We are confident that Djoule will become a trusted ally in your daily journeys. Once again, we would like to express our 
    sincere appreciation for choosing Djoule as your automation tool. We look forward to following your experiences and continuing to improve our app based on 
    your needs and feedback. Welcome to Djoule, where efficiency meets simplicity. We are ready to embark on this journey of optimization and productivity at your 
    side. Thank you for being part of this exciting phase of Djoule. 
`;
export const img1 = `/images/montanhas.jpg`;
export const Component1 = () => {
  const iconStyle = { marginRight: "30px" };

  return (
    <div style={{ color: "#ffffffd3" }}>
      <HiCursorClick size={82} style={iconStyle} />
      <RiShieldFlashFill size={72} style={iconStyle} />
      <FaStore size={72} style={iconStyle} />
      <BiMoneyWithdraw size={72} style={iconStyle} />
      <FaGear size={72} />
    </div>
  );
};
