export const text4 = `
This area is just a sample of how the tool looks, in it you can observe the fields that you will use during the automation processes and even have fun moving the blocks that we make available to you, Djoule from here starts to become a very interesting tool, let's move on to the next chapter, if you have any questions so far, read the previous chapters or post your question on social help networks and forums that provide services to the Djoule community. Good automations!.
`;
export const img4 = `/images/djouleAppearance1.png`;

export const Component4 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-223px 0 0 -410px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          View, create, update fields for automations.
        </span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Inspect automation criticalities.
        </span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Putting automation to work in the cloud.
        </span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>Add a payment method.</span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>Back to login page.</span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>Create automations.</span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "163px 0 0 -410px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>Account information.</span>
      </div>
    </div>
  );
};
