export const text9 = `
We at djoule strongly recommend that any and all types of automation be done in F11 mode at a resolution of 1920x1080 if your resolution is not this it is possible that you need to resize your screen while using djoule services, these changes are easily done through the settings from your computer. in most cases, the simplest to the most robust computers already come with this factory resolution, so no changes are necessary. It is worth noting that the red dots both generated temporarily in the browser and those fixed in the images are not part of the automations and only serve to help you locate yourself in the automation.
`;
export const img9 = `/images/mechanics2.png`;
export const Component9 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-75px 0 0 -355px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        <span style={{ background: "#69362cc9" }}>
          When a click is generated a markup
          <br /> appears both on the captured image and in the
          <br /> browser for a moment
        </span>
        ---{">"}
      </div>
    </div>
  );
};
