import {
  Btnpopup,
  Headerpopup,
  PopupStyle,
  Popupbtn,
  Startpopup,
  Textpopup,
  Trash,
} from "../styles/styles";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { makeRequest } from "../requests/makerequest";
import { useQueryClient } from "@tanstack/react-query";

export const Trashs = ({ pid }) => {
  const queryClient = useQueryClient();

  const handleClickTrash = async (arg) => {
    const postId = arg;
    try {
      await makeRequest.delete("/posts/" + postId);
      queryClient.invalidateQueries(["click"]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup
        trigger={
          <Popupbtn>
            <Trash size={16} />
          </Popupbtn>
        }
        modal
        nested
        contentStyle={PopupStyle}
      >
        {(close) => (
          <Startpopup>
            <Headerpopup>Do you want to delete this automation?</Headerpopup>
            <Textpopup>
              Remember that when deleting any automation, you are confirming
              complete deletion of all Djoule servers,{" "}
              <span style={{ color: "tomato" }}>
                this means that you will never be able to access it again.
              </span>{" "}
              Are you sure you want to{" "}
              <span style={{ color: "tomato" }}>delete </span>this automation ?
            </Textpopup>
            <Btnpopup
              onClick={() => {
                handleClickTrash(pid);
                close();
              }}
            >
              DELETE
            </Btnpopup>
          </Startpopup>
        )}
      </Popup>
    </>
  );
};
