import {
  Background,
  Buttontb,
  CentralizeSp,
  Dedicated,
  Iflash,
  Inputtab,
  InputtabCp,
  Spbcont,
  Spbtexts,
  Table,
  WorkspaceDiv,
} from "../styles/styles";
import { Sidebar } from "../sidebars/sidebar";
import { Footers } from "../footers/footers";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../requests/makerequest";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const Views = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest.get("/users/viewUserPage");
      } catch (error) {
        navigate("/register");
      }
    };

    fetchData();
  }, [navigate]);

  const { isLoading, error, data } = useQuery(["click"], () =>
    makeRequest.get("/clicks/get-all-clicks").then((res) => {
      return res.data;
    })
  );

  const { pid } = useParams();

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (error) {
      console.log("Error...");
    } else if (isLoading) {
      console.log("Is loading...");
    } else if (data) {
      const foundItem = data.find((item) => item.postId === parseInt(pid));
      if (foundItem) {
        setDatas(foundItem.section);
      }
    }
  }, [data, error, isLoading, pid]);

  const handleChange = (event, sectionIndex, key) => {
    const newDatas = [...datas];
    newDatas[sectionIndex][key] = event.target.value;
    setDatas(newDatas);
  };

  const handleSave = async () => {
    datas.forEach((item, index) => {
      if (typeof item.copyPaste === "string") {
        datas[index].copyPaste = parseInt(item.copyPaste);
      }
    });

    console.log(datas);

    try {
      //prettier-ignore
      await makeRequest.put("/section/update-section-while-being-made/" + pid, { updateInputs: datas})
      queryClient.invalidateQueries(["click"]);
    } catch (err) {
      console.log(err);
    }
  };

  const [buttonState, setButtonState] = useState(true);
  const [countWins, setCountWins] = useState(0);

  useEffect(() => {
    setCountWins((prevCountWins) => prevCountWins + 1);
  }, [datas]);

  useEffect(() => {
    if (countWins === 3) {
      setButtonState(false);
    }
  }, [setButtonState, countWins]);

  return (
    <>
      {/* <StyledBlobComponent
        style={{ position: "fixed", bottom: "75%", right: "75%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "50%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}
      <Background>
        <Sidebar />
        <Iflash />
        <CentralizeSp>
          <WorkspaceDiv style={{ overflowX: "auto" }}>
            <Spbtexts>
              <Spbcont>
                <Dedicated>
                  This area is dedicated to automation modification.
                </Dedicated>
                <Buttontb onClick={handleSave} disabled={buttonState}>
                  Save
                </Buttontb>
              </Spbcont>
            </Spbtexts>
            <Table>
              <thead>
                <tr>
                  <th>Click</th>
                  <th>X</th>
                  <th>Y</th>
                  <th>Write</th>
                  <th>Interval</th>
                  <th>Navigate</th>
                  <th>CopyPaste</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((sectionItem, sectionIndex) => (
                  <tr key={sectionIndex}>
                    <td>{sectionIndex + 1}</td>
                    <td>
                      <Inputtab
                        type="number"
                        value={sectionItem.x}
                        onChange={(e) => handleChange(e, sectionIndex, "x")}
                        readOnly
                      />
                    </td>
                    <td>
                      <Inputtab
                        type="number"
                        value={sectionItem.y}
                        onChange={(e) => handleChange(e, sectionIndex, "y")}
                        readOnly
                      />
                    </td>
                    <td>
                      <Inputtab
                        type="text"
                        value={sectionItem.write}
                        onChange={(e) => handleChange(e, sectionIndex, "write")}
                      />
                    </td>
                    <td>
                      <Inputtab
                        type="number"
                        value={sectionItem.interval}
                        onChange={(e) => {
                          const inputValue = parseInt(e.target.value);

                          if (!isNaN(inputValue) && inputValue >= 0) {
                            handleChange(e, sectionIndex, "interval");
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Inputtab
                        type="text"
                        value={sectionItem.navigate}
                        onChange={(e) =>
                          handleChange(e, sectionIndex, "navigate")
                        }
                      />
                    </td>
                    <td>
                      <InputtabCp
                        type="number"
                        value={sectionItem.copyPaste}
                        onChange={(e) => {
                          const inputValue = parseInt(e.target.value);

                          if ([0, 1, 2].includes(inputValue)) {
                            handleChange(e, sectionIndex, "copyPaste");
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </WorkspaceDiv>
        </CentralizeSp>
        <Footers />
      </Background>
    </>
  );
};
