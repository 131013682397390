import { useEffect, useState } from "react";
import { Cadasterstyles } from "../components/registers/cadasters";
import { Loginstyles } from "../components/registers/logins";
import { toastErrorWarning } from "../components/toasts/toaststyles";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../components/requests/makerequest";

export const Register = () => {
  const [state, setState] = useState(false);
  const showState = () => setState(!state);

  const data = (datas) => showState();

  // useEffect(() => {
  //   if (localStorage.getItem("logedintoacess")) {
  //     toastErrorWarning("You must be logged in to access this section.");
  //     localStorage.removeItem("logedintoacess");
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("logoutaccount")) {
      toastErrorWarning("You are logout.");
      localStorage.removeItem("logoutaccount");
    }
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest.get("/users/viewUserPage");
        navigate("/workspace");
      } catch (error) {
        navigate("/register");
        localStorage.setItem("logedintoacess", null);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      {state ? (
        <>
          <Cadasterstyles data={data} />
        </>
      ) : (
        <>
          <Loginstyles data={data} />
        </>
      )}
    </>
  );
};
