import { Formik, Form } from "formik";
import { makeRequest } from "../../components/requests/makerequest";
import { useQuery } from "@tanstack/react-query";

import * as yup from "yup";
import {
  Background,
  Container,
  Iflash,
  Formblocks,
  Formcont,
  Fields,
  Button,
  Errors,
  // StyledBlobComponent,
  ButtonNavigate,
  FooterCell,
} from "../../components/styles/styles";
import { Link, useLocation } from "react-router-dom";
import {
  toastErrorResend,
  toastSuccessResend,
} from "../../components/toasts/toaststyles";
import { Sidebar } from "../../components/sidebars/sidebar";
import { Footers } from "../footers/footers";

const validationsLogin = yup.object().shape({
  email: yup.string().email("Invalid email.").required("Email is required."),
});

export const ForgotPasswords = () => {
  const handleLogin = async (values, actions) => {
    actions.resetForm();
    await makeRequest
      .post(
        "/auth/forgot-password",
        {
          email: values.email,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        toastSuccessResend(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        toastErrorResend(error.response.data);
      });
  };

  return (
    <>
      {/* <StyledBlobComponent
        style={{
          position: "fixed",
          bottom: "75%",
          right: "75%",
        }}
      />
      <StyledBlobComponent
        style={{
          position: "fixed",
          bottom: "50%",
          right: "25%",
        }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}

      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Formcont style={{ height: "auto" }}>
              <div>
                <h1 style={{ margin: "-5px 0 5px 0", color: "#757575" }}>
                  Email para mudar a senha
                </h1>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={handleLogin}
                  validationSchema={validationsLogin}
                >
                  <Form>
                    <Formblocks>
                      <Fields name="email" placeholder="Email" />

                      <Errors
                        component="span"
                        name="email"
                        style={{
                          margin: "-25px 0 0 5px",
                          textStroke: "black",
                        }}
                      />
                    </Formblocks>

                    <Button className="button" type="submit">
                      Enviar
                    </Button>
                    <Link
                      to="/register"
                      style={{
                        display: "block",
                        marginLeft: "5px",
                        marginTop: "2px",
                        color: "#757575",
                        fontWeight: "bolder",
                      }}
                    >
                      <ButtonNavigate style={{ marginLeft: "-5px" }}>
                        Volte ao login.
                      </ButtonNavigate>
                    </Link>
                  </Form>
                </Formik>
              </div>
            </Formcont>
          </div>
        </Container>
        <Footers />
        <FooterCell>
          <div style={{ color: "#c4cbda" }}>
            Automate everything with Djouleco
          </div>
        </FooterCell>
      </Background>
    </>
  );
};

const validationsLogins = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
});

export const RequestPassword = ({ hash }) => {
  const changePassword = async (values, actions) => {
    actions.resetForm();
    try {
      const response = await makeRequest.post(
        "/auth/confirm-secure-new-password",
        {
          password: values.password,
          hash: hash,
        },
        { withCredentials: true }
      );

      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else {
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <>
      {/* <StyledBlobComponent
        style={{
          position: "fixed",
          bottom: "75%",
          right: "75%",
        }}
      />
      <StyledBlobComponent
        style={{
          position: "fixed",
          bottom: "50%",
          right: "25%",
        }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", left: "-10%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", top: "-25%", right: "25%" }}
      />
      <StyledBlobComponent
        style={{ position: "fixed", bottom: "40%", left: "-20%" }}
      /> */}
      <Background>
        <Sidebar />
        <Iflash />
        <Container>
          <Formcont style={{ height: "auto" }}>
            <div>
              <h1 style={{ margin: "-5px 0 5px 0", color: "#757575" }}>
                New password
              </h1>
              <Formik
                initialValues={{
                  password: "",
                }}
                onSubmit={changePassword}
                validationSchema={validationsLogins}
              >
                <Form>
                  <Formblocks>
                    <Fields
                      name="password"
                      placeholder="Password"
                      type="password"
                    />

                    <Errors
                      component="span"
                      name="password"
                      style={{
                        margin: "-25px 0 0 5px",
                        textStroke: "black",
                      }}
                    />
                  </Formblocks>

                  <Button className="button" type="submit">
                    Change password
                  </Button>
                  <Link
                    to="/register"
                    style={{
                      display: "block",
                      marginLeft: "5px",
                      marginTop: "2px",
                      color: "#757575",
                      fontWeight: "bolder",
                    }}
                  >
                    <ButtonNavigate style={{ marginLeft: "-5px" }}>
                      Back to login.
                    </ButtonNavigate>
                  </Link>
                </Form>
              </Formik>
            </div>
          </Formcont>
        </Container>
        <Footers />
        <FooterCell>
          <div style={{ color: "#c4cbda" }}>
            Automate everything with Djouleco
          </div>
        </FooterCell>
      </Background>
    </>
  );
};

export const ChangePasswordComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hash = queryParams.get("hash");

  const { isLoading, error, data } = useQuery(["userhash"], () =>
    makeRequest.get("/users/get-user-hash/" + hash).then((res) => {
      return res.data;
    })
  );

  return (
    <div>
      {error ? (
        "Error..."
      ) : isLoading ? (
        "isLoading..."
      ) : hash === data ? (
        <RequestPassword hash={hash} />
      ) : (
        <h1>Invalid Link</h1>
      )}
    </div>
  );
};
