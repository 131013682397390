import React from "react";
import { PaymentAdds } from "../components/paymentadd/paymentadds";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { makeRequest } from "../components/requests/makerequest";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// const stripePromise = loadStripe(
//   "pk_test_51NQsvEKWMw74BE9jLmp7xr8cfZUlNhJm1oQHrCtG9Qyb7wPQrKxYg9mg6e1aECx1e32JNA9UrOwvGhopJlPPfVW90092nJLntM"
// );

const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);

export const PaymentAdd = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest.get("/users/viewUserPage");
      } catch (error) {
        navigate("/register");
        localStorage.setItem("logedintoacess", null);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentAdds />
      </Elements>
    </>
  );
};
