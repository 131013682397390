export const text5 = `
Here you can see how the sidebar for managing your automations would look like, just follow the steps in the image to complete your first virtual space to include automations, NOTE: this area is completely exclusive to you, no one without authorized access can see them, in case If you want to share automations, we'll show later how you can do that.
`;
export const img5 = `/images/djouleAppearance2.png`;
export const Component5 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-212px 0 0 -290px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Here you enter the name of your space for automation
        </span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>General space.</span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>Back to workspace.</span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Got to mechanics {"(Docs)"}.
        </span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Spaces created for your automations.
        </span>
        <br />
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Space for an automation created.
        </span>
      </div>
    </div>
  );
};
