import { Links } from "../links/links";
import {
  Rank1,
  RankD,
  SpanText,
  Txtlime,
  Txtlimes,
  MoneyLink,
} from "../styles/styles";
import { TextWages } from "../wages/textWage";

export const Footers = () => {
  return (
    <>
      <Rank1
        style={{
          position: "fixed",
          bottom: "0",
          height: "15px",
          width: "calc(100% - 140px)",
          alignItems: "center",
          display: "flex",
          padding: "20px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Txtlimes
          style={{
            fontSize: "16px",
            padding: "20px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SpanText>
            Confira o rendimento global{" "}
            <MoneyLink to="/wages">
              <TextWages />
            </MoneyLink>{" "}
            e caso esteja começando agora confira <Links />
          </SpanText>
        </Txtlimes>
      </Rank1>
    </>
  );
};

export const FootersDocs = () => {
  return (
    <>
      <RankD
        style={{
          position: "fixed",
          bottom: "0",
          height: "15px",
          width: "370px",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Txtlime
          style={{
            fontSize: "15px",
            padding: "70px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
            position: "fixed",
          }}
        >
          <span style={{ overflow: "auto", height: "50px" }}>
            Welcome to <Links />. Here you can find how the mechanics behind the
            tool work.
          </span>
        </Txtlime>
      </RankD>
    </>
  );
};

export const FootersDocsR = () => {
  return (
    <>
      <RankD
        style={{
          position: "fixed",
          bottom: "0",
          height: "15px",
          width: "370px",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
          justifyContent: "center",
          right: "0",
        }}
      >
        <Txtlime
          style={{
            fontSize: "15px",
            padding: "70px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
            position: "fixed",
          }}
        >
          <div style={{ overflow: "auto", height: "50px" }}>
            Welcome to <Links />. Here you can find how the mechanics behind the
            tool work.
          </div>
        </Txtlime>
      </RankD>
    </>
  );
};
