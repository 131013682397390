import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

export const Links = styled(Link)`
  text-decoration: none;
  color: #757575;
`;

export const toastError = (param, paramI) => {
  toast.error(
    <Links to={"/verifications"}>
      {param} <div style={{ textDecoration: "underline" }}>{paramI}</div>
    </Links>,
    {
      position: "top-center",
      autoClose: 50000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",

      progressStyle: {
        background: "tomato",
      },
      style: {
        fontSize: "20px",
        color: "#757575",
        background: "#232323",
      },
    }
  );
};

export const toastSuccess = (param) => {
  toast.success(<Links to={"/register"}>{param}</Links>, {
    position: "top-center",
    autoClose: 50000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    icon: (
      <BsFillCheckCircleFill
        style={{
          fontSize: "28px",
          color: "#A5BBE3",
        }}
      />
    ),

    progressStyle: {
      background: "#A5BBE3",
    },

    style: {
      fontSize: "20px",
      color: "#757575",
      background: "#232323",
    },
  });
};

export const toastErrorResend = (param) => {
  toast.error(<Links to={"/register"}>{param}</Links>, {
    position: "top-center",
    autoClose: 50000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",

    progressStyle: {
      background: "tomato",
    },

    style: {
      fontSize: "20px",
      color: "#757575",
      background: "#232323",
    },
  });
};

export const toastSuccessResend = (param) => {
  toast.error(
    <div>
      <Links to={"/register"}>{param}</Links>
    </div>,
    {
      position: "top-center",
      autoClose: 50000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      progressStyle: {
        background: "#A5BBE3",
      },
      style: {
        fontSize: "20px",
        color: "#757575",
        background: "#232323",
      },
    }
  );
};

export const toastErrorWarning = (param) => {
  toast.warning(<Links to={"/register"}>{param}</Links>, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",

    // progressStyle: {
    //   background: "tomato",
    // },

    style: {
      fontSize: "20px",
      color: "#757575",
      background: "#232323",
    },
  });
};

export const PromisseSucess = (param) => {
  return {
    success: {
      render: `${param}`,
      icon: (
        <BsFillCheckCircleFill
          style={{
            fontSize: "28px",
            color: "#A5BBE3",
          }}
        />
      ),
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      progressStyle: {
        background: "#A5BBE3",
      },
    },
  };
};

export const PromisseLoading = (param) => {
  return {
    pending: {
      render: `${param}`,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      progressStyle: {
        background: "#A5BBE3",
      },
    },
  };
};

export const PromisseError = (param) => {
  return {
    error: {
      render: `${param}`,
      icon: (
        <BsFillXCircleFill
          style={{
            fontSize: "28px",
            color: "tomato",
          }}
        />
      ),
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      progressStyle: {
        background: "tomato",
      },
    },
  };
};

export const toastJustError = (param) => {
  toast.error(
    <div>
      <div style={{ marginRight: "10px" }}>{param} </div>
    </div>,
    {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",

      progressStyle: {
        background: "tomato",
      },
      style: {
        fontSize: "20px",
        color: "#757575",
        background: "#232323",
      },
    }
  );
};
