export const text18 = `
To insert an automation in the cloud and play it, you will first need to click on the play icon next to the taskbar, then you will see a message to add payment method, don't worry, both on the web and in the app you can manage the two and when inserting the card no charge will be made.
`;

export const img18 = `/images/puttingcloud1.png`;
export const Component18 = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-142px 0 0 135px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here if you <br />
          stay on the web
        </span>
      </div>
      <div
        style={{
          position: "absolute",
          color: "tomato",
          margin: "-60px 0 0 -385px",
          fontSize: "21px",
          fontWeight: "bolder",
        }}
      >
        {"<"}---
        <span style={{ background: "#69362cc9" }}>
          Click here if you stay on the app
        </span>
      </div>
    </div>
  );
};
